import { faArrowCircleDown, faArrowCircleUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from 'react'
import { ChildRecordProvider } from "../../../contexts/childRecordContext"
import { alphaNumericRecordEntries } from "../../../utilities/mobileRecordHelper"
import ChildRecord from './childRecord'

const GroupedChildRecords = ({ recordType, records }) => {
  const [showRecords, setShowRecords] = useState(false)
  const tableHeaders = alphaNumericRecordEntries(records[0]).entries
  return (
    <div className='flex-col bg-gray-200 flex w-full'>
      <div className='w-8 items-center flex hover:bg-gray-200 p-2 cursor-pointer gap-x-2 w-full'
        onClick={() => setShowRecords(!showRecords)}>
        <FontAwesomeIcon icon={showRecords ? faArrowCircleUp : faArrowCircleDown} size='lg' />
        <h2 className='w-8 uppercase whitespace-nowrap leading-none'>
          {recordType}
        </h2>
      </div>
      {showRecords &&
        <div className="overflow-x-auto w-full p-2">
          <table className="table-auto border-collapse border border-gray-300">
            <thead>
              <tr>
                {tableHeaders.map(header => <th key={header.id} className='border border-gray-300 p-2'>{header.label}</th>)}
              </tr>
              {records.map(record =>
                <ChildRecordProvider key={record.id} mobileRecord={alphaNumericRecordEntries(record)}>
                  <ChildRecord />
                </ChildRecordProvider>
              )}
            </thead>
          </table>
        </div>
      }
    </div>
  )
}

export default GroupedChildRecords 
