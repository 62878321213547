import React from "react"
import { useChecklistAPI, useChecklistContext } from "../../../contexts/checklistContext"

const CollapsedStyleButton = () => {
  const { toggleCollapsed } = useChecklistAPI()
  const { collapsed } = useChecklistContext()

  const buttonText = collapsed ? "Toggle Regular Style" : "Toggle Collapsed Style"

  return (
    <button
      className="btn btn-primary font-semibold mx-4 my-4 relative cursor-pointer z-10"
      onClick={toggleCollapsed}
    >
      {buttonText}
    </button>
  )
}

export default CollapsedStyleButton
