import React, { useMemo } from "react"
import { parseDateString, syncAtFormatter } from "../../../utilities/dateHelpers"

const SyncedAt = ({ project }) => {
  const syncedAt = useMemo(() => {
    return project.synced_at ? syncAtFormatter(parseDateString(project.synced_at)) : 'N/A'
  }, [project.synced_at])

  return (
    <span>
      { `Last synced at: ${ syncedAt }` }
    </span>
  )
}

export default SyncedAt
