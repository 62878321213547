import React from 'react'
import { isEmpty } from "lodash"
import { useAiDocumentContext } from "../../../contexts/aiDocumentContext"
import LoadingSpinner from "../../shared/loadingSpinner"

const QueryResults = () => {
  const { queryResults, loading } = useAiDocumentContext()

  return (<>
    { loading?.queries && (
      <p className="text-3xl font-bold flex items-center justify-start">Running Queries...&nbsp;&nbsp;&nbsp;
        <LoadingSpinner size='2xl'/>
      </p>) }
    { !isEmpty(queryResults) && (
      <div className="w-full flex flex-col py-4">
        <h2 className="text-2xl font-bold">Document Query Results</h2>
        <div className="w-full grid grid-cols-12 gap-4 border border-solid border-gray-300 p-4">
          <h3 className="text-2xl font-bold col-span-2 text-center">APEC</h3>
          <h3 className="text-2xl font-bold col-span-2 text-center">Page Header</h3>
          <h3 className="text-2xl font-bold col-span-6 text-center">Summary of Findings</h3>
          <h3 className="text-2xl font-bold col-span-2 text-center">Page Number</h3>
        </div>
        { queryResults.map(queryResult => (
          <div key={ queryResult.id } className="w-full grid grid-cols-12 border border-solid border-gray-300 p-4">
            <p className="text-xl col-span-2 text-center">{ queryResult?.query_name }</p>
            <p className="text-xl col-span-2 text-center">{ queryResult?.page_title }</p>
            <p className="text-xl col-span-6 text-center">{ queryResult?.value }</p>
            <p className="text-xl col-span-2 text-center">{ queryResult?.page_number }</p>
          </div>
        )) }
      </div>
    ) }
  </>)
}

export default QueryResults
