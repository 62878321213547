import { isEmpty } from 'lodash'
import React from 'react'
import { tableStyles } from '../../../utilities/stylesHelpers'

const ApprovalDay = ({ day, dayEntry }) => {
  const latestVersion = dayEntry?.versions?.slice(-1)[0]
  const createdAt = latestVersion?.created_at

  return (
    <th className={tableStyles.th}>
      <div className={tableStyles.thContent}>
        {isEmpty(dayEntry) ? '' : dayEntry?.approved ? "Approved" : "Pending"}
        {createdAt && dayEntry?.approved && (
          <span className="text-xs text-gray-400 block">
            {latestVersion?.created_by?.first_name} - {latestVersion?.created_by?.last_name} 
            {` Date: ${createdAt.slice(0, 10)}`}
          </span>
        )}
      </div>
    </th>
  )
}

export default ApprovalDay
