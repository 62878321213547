import { faArrowCircleLeft, faArrowCircleRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from 'react'
import { useMobileRecordContext } from "../../../contexts/mobileRecordContext"
import Entry from "./entry"

const BoreholeDetails = ({children}) => {
  const [showBoreholeDetails, setShowBoreholeDetails] = useState(false)
  const { mobileRecord } = useMobileRecordContext()
  const entries = mobileRecord?.entries
  return (<>
    <div className='flex-row bg-gray-200 flex'>
      {showBoreholeDetails && <div className='flex flex-col w-full gap-y-4 p-2'>
        {entries && entries.map(entry => {if (entry.name !=='boreholeId') return <Entry key={entry.id} entry={entry} />})}
      </div>}
      <div className='w-8 items-center flex flex-col hover:bg-gray-200 p-2 cursor-pointer gap-2'
        onClick={() => setShowBoreholeDetails(!showBoreholeDetails)}>
        <FontAwesomeIcon icon={showBoreholeDetails ? faArrowCircleLeft : faArrowCircleRight} size='lg' />
        <p className='uppercase whitespace-nowrap leading-none' style={{ writingMode: 'vertical-lr' }}>
          Borehole Details
        </p>
      </div>
    </div>
    {children}
    </>
    )
}

export default BoreholeDetails
