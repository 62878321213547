import React, { useMemo, useState } from 'react'
import Select from "react-select"
import Modal from "../../shared/modal"
import { useAiDocumentAPI, useAiDocumentContext } from "../../../contexts/aiDocumentContext"
import { optionize } from "../../../utilities/utils"

const QueryModal = ({ modals }) => {
  const { availableQueryCollections } = useAiDocumentContext()
  const { toggleModal, initiateQuerying } = useAiDocumentAPI()
  const [selectedQueryCollection, setSelectedQueryCollection] = useState('')
  const queryCollectionOptions = useMemo(() => {
    return optionize('name', 'id')(availableQueryCollections)
  }, [availableQueryCollections])

  const onSelect = (e) => setSelectedQueryCollection(e?.value)
  const onSubmit = () => {
    initiateQuerying(selectedQueryCollection)
    toggleModal('query')
  }

  return (
    <>
      { modals.query && (
        <Modal heading={ 'Choose Query Collection:' } closeModal={ () => toggleModal('query') } onSave={ onSubmit }>
          <div className="m-4 z-50 flex flex-row gap-x-4 justify-between sticky left-4">
            <Select options={ queryCollectionOptions } onChange={ onSelect } className='w-1/2 z-50' isClearable='true'/>
          </div>
        </Modal>
      ) }
    </>
  )
}

export default QueryModal
