import React from 'react'
import Widget from "../shared/widget"

const Layout = ({ children, project, showMap }) => {
  return (
    <div className={`flex flex-row flex-grow overflow-hidden`} >
      <div className={`flex flex-col h-1/3 lg:h-auto mr-4 w-1/4`}>
        <Widget widgetProp={project?.default_widget?.full_url} project={project} widgetStyles="h-full sm:p-0" />
      </div>
      <div className={`flex flex-col w-full h-2/3 ${showMap && 'lg:h-full lg:w-3/4'}  flex-grow overflow-scroll gap-y-1`}>
        <div>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Layout
