import { truthConverter } from "./helpers"
import { isValid } from "date-fns"
import { parseDateString } from "./dateHelpers"

export const tableStyles = {
  td: "border-gray-200 border text-center relative p-4 pb-4 align-middle group bg-white",
  tdText: "text-left w-full"
}

export const simpleContentValue = (content, header) => {
  switch (header.type) {
    case 'BooleanHeader':
      return truthConverter[content?.value] ? "Yes" : "No"
    case 'CurrencyHeader':
      return new Intl
        .NumberFormat('en-CA', { style: 'currency', currency: 'CAD', })
        .format(content?.value || 0)
    case 'NumberHeader':
    case 'WholeNumberHeader':
      return new Intl.NumberFormat().format(content?.value || 0)
    case 'MathHeader':
      return content
    case 'DateHeader':
      const parsedContent = parseDateString(content?.value)
      if (isValid(parsedContent)) return parsedContent.toDateString()
    default:
      return content?.value
  }
}
