import React, { useEffect, useMemo, useState } from "react"
import { isEmpty } from "lodash"
import { useParams } from "react-router"
import { Link } from "react-router-dom"
import { useProjectContext } from "../../../contexts/projectContext"
import LoadingSpinner from "../../shared/loadingSpinner"
import { DemoLinks } from "../../shared/demo"
import ExportDropdown from "./exportDropdown"
import NotesDropdown from "./notesDropdown"
import SidebarDropdown from "../../shared/sidebarDropdown"
import DataCollectionDropdown from "./dataCollectionDropdown"
import UploadCsv from "../../trackers/uploadCsv"
import { getOrganization } from "../../../api/organizationApi"
import { buildChecklistPath, getChecklist } from "../../../api/checklist"
import { getReportTemplates } from "../../../api/reportsApi"
import { getTracker } from "../../../api/trackers"
import {
  mapChecklistToLabelValue,
  mapReportToLabelValue,
  mapTrackerToLabelValue
} from "../../../utilities/portfolioHelpers"
import { createDataCollectionArray } from "../../../utilities/projectHelpers"
import { pipe } from "../../../utilities/utils"

const ProjectSideBar = ({ project, routes, changeWidget, containerClasses = '' }) => {
  const { projectId, trackerSlug } = useParams()
  const { portfolioType, portfolio } = useProjectContext()

  const [defaultTrackers, setDefaultTrackers] = useState([])
  const [permitTrackers, setPermitTrackers] = useState([])
  const [historicalTracker, setHistoricalTracker] = useState()
  const [interviewTracker, setInterviewTracker] = useState()
  const [organization, setOrganization] = useState({})
  const [checklists, setChecklists] = useState([])
  const [reportTemplates, setReportTemplates] = useState([])

  const checklistPath = buildChecklistPath({ projectId })
  const priority_checklist_slug = portfolio?.priority_checklist_slug
  const { portfolio_id } = project

  const isNotesPage = useMemo(() => window.location.pathname.includes('notes'), [])
  const notesLabel = useMemo(() => {
    return portfolioType === 'Assessment' ? 'Site Assessment' : 'All Form Entries'
  }, [portfolioType])

  const dataCollectionArray = useMemo(() => {
    return createDataCollectionArray(
      projectId, isNotesPage, portfolioType, notesLabel, !!project?.document_builder_templates?.length
    )
  }, [projectId, isNotesPage, portfolioType, notesLabel, project])

  useEffect(() => {
    if (trackerSlug) getOrganization().then(res => setOrganization(res))
    if (portfolio_id) {
      getTracker(`/projects/${ projectId }/trackers.json`)
        .then(response => {
          pipe(mapTrackerToLabelValue('projects', projectId), setDefaultTrackers)(response.default_trackers)
          pipe(mapTrackerToLabelValue('projects', projectId), setPermitTrackers)(response.permit_trackers)
          setHistoricalTracker(response.historical_tracker)
          setInterviewTracker(response.interview_tracker)
        })
      getChecklist(checklistPath)
        .then(response =>
          pipe(mapChecklistToLabelValue('projects', projectId), setChecklists)(response.checklists)
        )
      getReportTemplates({ projectId })
        .then(response => {
          pipe(mapReportToLabelValue('projects', projectId), setReportTemplates)(response)
        })
    }
  }, [])

  return (
    project ? (
      <div className={ `p-8 lg:w-1/4 bg-gray-200 h-full overflow-y-scroll ${ containerClasses }` }>
        <div className="w-full h-fit flex-col lg:flex max-h-[calc(100vh - 161px)]">
          <div className="flex flex-col gap-3">
            <DemoLinks changeWidget={ changeWidget } propProject={ project }/>
            { !isEmpty(dataCollectionArray) && <DataCollectionDropdown dataCollectionArray={ dataCollectionArray }/> }
            { !isEmpty(checklists) && <SidebarDropdown options={ checklists } selectionType={ "Checklist" }/> }
            { !isEmpty(defaultTrackers) && <SidebarDropdown options={ defaultTrackers } selectionType={ "Tracker" }/> }
            { !isEmpty(permitTrackers) && <SidebarDropdown options={ permitTrackers } selectionType={ "Permit" }/> }
            { !isEmpty(reportTemplates) && <SidebarDropdown options={ reportTemplates } selectionType={ "Report" }/> }

            { portfolioType !== 'Assessment' && <ExportDropdown routes={ routes }/> }
            { portfolioType !== 'Assessment' && <NotesDropdown/> }
          </div>
          <div className="flex flex-col gap-y-4 pt-6">
            { historicalTracker && (
              <a
                href={ `/projects/${ projectId }/trackers/${ historicalTracker.slug }` }
                className="btn btn-outline truncate w-full text-center mb-6"
                target="_blank" rel="noreferrer noopener"
              >
                Historical Document Tracker
              </a>
            ) }
            { interviewTracker && (
              <a
                href={ `/projects/${ projectId }/trackers/${ interviewTracker.slug }` }
                className="btn btn-outline truncate w-full text-center mb-6"
                target="_blank" rel="noreferrer noopener"
              >
                Interview Log
              </a>
            ) }
            { priority_checklist_slug && (
              <a
                href={ `/projects/${ projectId }/checklists/${ priority_checklist_slug }` }
                className="btn btn-outline truncate w-full text-center mb-6"
                target="_blank" rel="noreferrer noopener"
              >
                Stage 1 Checklist
              </a>
            ) }
            { trackerSlug && <Link className="btn btn-outline truncate w-full text-center"
                                   to={ `/projects/${ projectId }/trackers/${ trackerSlug }/change-log` }>
              Tracker Change Log
            </Link> }
            { trackerSlug && organization?.tracker_upload_permission && project && <UploadCsv/> }
            <div className="flex flex-col gap-y-4 pt-6 mt-24">
              { !isEmpty(reportTemplates) &&
                <a className="btn btn-primary truncate w-full text-center"
                   href={ `/projects/${ project.id }/reports-export` }>
                  Project Report Export
                </a>
              }
              <a className="btn btn-outline truncate w-full text-center" href={ `/portfolios/${ portfolio_id }` }>
                Back to Portfolio
              </a>
            </div>
          </div>
        </div>
      </div>
    ) : <LoadingSpinner/>
  )
}

export default ProjectSideBar
