import React, { useReducer } from "react"
import ChecklistUpdateModal from "../checklistModal/checklistUpdateModal"

const UpdateChecklistButton = ({ projectChecklist }) => {
  const [show, toggleShow] = useReducer(showState => !showState, false)

  return (<>
    <button
      className="btn btn-primary font-semibold mx-4 my-4 relative cursor-pointer z-10 "
      onClick={toggleShow}
    >
      Update Checklist Information
    </button>
    <ChecklistUpdateModal projectChecklist={projectChecklist} show={show} onClose={toggleShow} />
  </>
  )
}

export default UpdateChecklistButton
