import React, { useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

export const notifyAlert = (notice, options = {}) => {
  toast.success(
    <p className='ml-1'>{ notice }</p>,
    {
      icon: faCheckCircle,
      ...options
    }
  )
}

export const notifyError = (alert, options = {}) => {
  toast.error(
    <p className='ml-1'>{ alert }</p>,
    {
      icon: faTimesCircle,
      autoClose: false,
      ...options
    }
  )
}

export const notifyInfo = (alert, options = {}) => {
  toast.info(
    <p className='ml-1'>{ alert }</p>,
    {
      icon: faTimesCircle,
      ...options
    }
  )
}

const Notice = ({ notice, alert, options = {} }) => {

  const notify = () => {
    if (notice) notifyAlert(notice, options)
    else if (alert) notifyError(alert, options)
  };

  useEffect(() => {
    if (notice || alert) notify()
  }, [])

  return (
    <ToastContainer
      style={ notice && { marginTop: '4em' } }
      className="text-white text-base w-full sm:w-8/12 lg:w-4/12"
      toastClassName="text-white text-base w-full"
      position='top-center'
      autoClose={ alert ? false : 5000 }
      hideProgressBar={ true }
      closeOnClick={ true }
      theme="colored"
    />
  )
}

export default Notice
