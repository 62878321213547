import { isEmpty } from 'lodash'
import React from 'react'
import { useChildRecordContext } from '../../../contexts/childRecordContext'
import EntryValue from './entryValue'

const ChildRecord = () => {
  const { mobileRecord } = useChildRecordContext()
  return (<tr>
    {!isEmpty(mobileRecord) &&
      <>
        {mobileRecord?.entries.map(entry => (
          <td key={entry.id}>
            <EntryValue entry={entry} />
          </td>
        ))}
      </>}
  </tr>
  )
}

export default ChildRecord
