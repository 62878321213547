import axios from "axios"
import React from "react"
import { useParams } from "react-router-dom"
import { usePermitApiContext } from "../../../contexts/permitContext"
import { useProjectContext } from "../../../contexts/projectContext"
import { notifyAlert, notifyError, notifyInfo } from "../../shared/notice"
import UploadCsv from "../../shared/uploadCsv"
import BreadCrumbs from "../nav/breadCrumbs"
import ViewPermitPdfButton from "../permits/viewPermitPdfButton"
import DeletePermitsButton from "./deletePermitButton"

const PermitHeader = () => {
  const { permitId } = useParams()
  const { portfolio, project } = useProjectContext()
  const { getPermits, setPermits, routes } = usePermitApiContext()

  const onPermitsDelete = () => {
    const confirmDelete = window.confirm('Are you sure you would like to delete all permit data for this project?')
    const token = document.querySelector('[name=csrf-token]').content
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token

    if (!confirmDelete) {
      return notifyInfo('Permits not deleted!')
    }

    axios
      .delete(routes.permitsPath + '/destroy_all')
      .then(() => {
        notifyAlert('Permits successfully deleted!')
        setPermits([])
      })
      .catch(err => {
        notifyError(`Error: ${err.message}`)
        console.error(err)
      })
  }

  return (
    <div className='flex flex-row justify-between'>
      <BreadCrumbs portfolio={portfolio} project={project} routes={routes} />
      <div className='actions flex items-center flex gap-x-2 mr-2'>
        {permitId && (
          <ViewPermitPdfButton />
        )}
        <DeletePermitsButton onPermitsDelete={onPermitsDelete} />
        <UploadCsv getPermits={getPermits} />
      </div>
    </div>
  )
}

export default PermitHeader
