import { isEmpty } from "lodash"
import React, { useMemo } from "react"
import { useProjectContext } from "../../contexts/projectContext"
import { getGisPdfTemplates } from "../../utilities/formTemplateHelpers"
import GisPdfButtonRow from "./gisPdfButtonRow"

const GisPdfButtons = ({ formData, formTemplate }) => {
  const { gisPdfTemplates } = useProjectContext()

  const gisPdfTemplate = useMemo(() => {
    return getGisPdfTemplates(gisPdfTemplates, formTemplate.name)
  }, [gisPdfTemplates, formTemplate.name])

  return (
    <>
      { !isEmpty(gisPdfTemplate) && !isEmpty(formData) && (
        <>
          { formData.map((formDatum, index) => (
            <GisPdfButtonRow
              key={ formDatum.id }
              formDatum={ formDatum }
              gisPdfTemplate={ gisPdfTemplate }
              buttonNumber={ formData.length > 1 && index + 1 }
            />
          )) }
        </>
      ) }
    </>
  )
}

export default GisPdfButtons
