import React, { createContext, useContext, useEffect, useMemo, useReducer } from "react"
import { INIT_FILTER_LISTS, SELECT_FILTER, filterReducer, initialFilterState } from "../reducers/noteFilterReducer"
import { useNotesContext } from "./notesContext"

/* Contexts */
const NoteFilterContext = createContext(initialFilterState)

const NoteFilterApiContext = createContext({
  initializeFilterLists: () => {},
  setFilter: () => {},
})

/* Providers */
export const NoteFilterProvider = ({ children }) => {
  const [state, dispatch] = useReducer(filterReducer, initialFilterState)
  const { notes } = useNotesContext()

  const api = useMemo(() => {
    const initializeFilterLists = (notes) => dispatch({ type: INIT_FILTER_LISTS, payload: notes })

    const setFilter = (filter) => (selection) => dispatch({ type: SELECT_FILTER, payload: { filter: filter, selection: selection } })

    return { initializeFilterLists, setFilter }
  }, [])

  useEffect(() => {
    api.initializeFilterLists(notes)
  }, [notes])

  return(
    <NoteFilterApiContext.Provider value={api}>
      <NoteFilterContext.Provider value={state}>
        {children}
      </NoteFilterContext.Provider>
    </NoteFilterApiContext.Provider>
  )
}

/* Custom Context Hooks */
export const useNoteFilterContext = () => useContext(NoteFilterContext)
export const useNoteFilterApi = () => useContext(NoteFilterApiContext)
