import { faArrowCircleLeft, faArrowCircleRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import React, { useEffect, useState } from "react"
import VerticalFormFieldGroup from "../formData/verticalFormFieldGroup"

const BoreholeDetails = ({ note }) => {
  let [showBoreholeDetails, setShowBoreholeDetails] = useState(false)
  let [boreholeDetails, setBoreholeDetails] = useState()
  let [formTemplate, setFormTemplate] = useState()

  useEffect(() => {
    axios.get(note.routes.boreholeDetailsRoute).then(res => setBoreholeDetails(res.data))
  }, [note])

  useEffect(() => {
    boreholeDetails?.form_template_id &&
      axios
        .get(`${note.routes.formTemplatesRoute}?borehole_template=${boreholeDetails.form_template_id}`)
        .then(res => {
          setFormTemplate(res.data[0])
        }
        )
  }, [boreholeDetails])

  return boreholeDetails && formTemplate ?
    <div className='flex-row bg-gray-200 flex'>
      {showBoreholeDetails && <div className='flex flex-col w-full gap-y-4'>
        {formTemplate && formTemplate.form_fields.map(formField => <VerticalFormFieldGroup key={formField.id}
          formField={formField}
          formDatum={boreholeDetails} />)}
      </div>}
      <div className='w-8 items-start flex flex-col hover:bg-gray-200 p-2 cursor-pointer'
        onClick={() => setShowBoreholeDetails(!showBoreholeDetails)}>
        <FontAwesomeIcon icon={showBoreholeDetails ? faArrowCircleLeft : faArrowCircleRight} size='lg' />
        <div className='relative flex flex-1 flex-col flex-grow w-full pt-2'>
          <div className='rotate-90 uppercase transform whitespace-nowrap origin-center top-0 left-0'>Borehole Details
          </div>
        </div>
      </div>
    </div> : <></>
}

export default BoreholeDetails
