import React, { createContext, useContext, useEffect, useMemo, useReducer } from "react"
import { getTimesheet, getWeekOfDayEntries } from "../api/timesheetsApi"
import { getWeekStart, incrementDay, stringToUTCDate } from "../utilities/dateHelpers"

/* Actions */
const ACTIONS = {
  INIT: "INIT",
  UPDATE_STATE: "UPDATE_STATE",
  UPDATE_WEEK: "UPDATE_WEEK",
  INCREMENT_WEEK: "INCREMENT_WEEK",
  ADD_TIMETABLES: "ADD_TIMETABLES",
}

/* Initial States */
const initialState = {
  template: {},
  tasks: [],
  timeTables: [],
  user: {},
  users: [],
  organization: {},
  projects: {},
  portfolios: {},
  selectedUsers: [],
  weekStart: getWeekStart(stringToUTCDate(new Date())),
}

const initState = ({ user, users, template, tasks, entries, projects, portfolios }) => (
  {
    users: users, template: template, tasks: tasks, timeTables: entries, user: user, organization: user.organization, projects: projects, portfolios: portfolios
  })


/* Reducer */
const timesheetReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.INIT:
      return { ...state, ...initState(action.initState) }
    case ACTIONS.UPDATE_STATE:
      return { ...state, [action.field]: action.value }
    case ACTIONS.UPDATE_WEEK:
      return { ...state, weekStart: getWeekStart(action.value) }
    case ACTIONS.INCREMENT_WEEK:
      return { ...state, weekStart: action.newValue }
    case ACTIONS.ADD_TIMETABLES:
      return { ...state, timeTables: { ...state.timeTables, ...action.timeTables } }
    default:
      return state
  }
}

/* Contexts */
const TimesheetContext = createContext(initialState)
const TimesheetApiContext = createContext({})

/* Providers */
export const TimesheetProvider = ({ children }) => {
  const [state, dispatch] = useReducer(timesheetReducer, initialState)

  const api = useMemo(() => {

    const updateState = (field, value) => dispatch({ type: ACTIONS.UPDATE_STATE, field, value })

    const updateSelectedUsers = value => updateState('selectedUsers', value)

    const addTimeTables = timeTables => dispatch({ type: ACTIONS.ADD_TIMETABLES, timeTables })

    return {
      updateState, updateSelectedUsers, addTimeTables
    }
  }, [])

  api.getUserEntries = async () => { getWeekOfDayEntries(state.weekStart, state.selectedUsers).then(res => api.addTimeTables(res)) }
  
  api.updateWeek = async(value) => { getWeekOfDayEntries(value, [state.user.id, ...state.selectedUsers]).then(res => {dispatch({ type: ACTIONS.UPDATE_WEEK, value }), api.addTimeTables(res)}) }

  api.incrementWeek = async(value) => {const newValue =incrementDay(state.weekStart, value)
    getWeekOfDayEntries(newValue, [state.user.id, ...state.selectedUsers]).then(res => {dispatch({ type: ACTIONS.INCREMENT_WEEK, newValue }), api.addTimeTables(res)})}
    
// Initialize state
useEffect(() => void getTimesheet().then(res => dispatch({ type: ACTIONS.INIT, initState: res })), [])

return (
  <TimesheetApiContext.Provider value={api}>
    <TimesheetContext.Provider value={state}>
      {children}
    </TimesheetContext.Provider>
  </TimesheetApiContext.Provider>
)
}

/* Custom Context Hooks */
export const useTimesheetContext = () => useContext(TimesheetContext)
export const useTimesheetApi = () => useContext(TimesheetApiContext)
