import React, { useMemo } from "react"
import { notifyAlert, notifyError } from "../shared/notice";
import { useSiteInspectionReports, useSiteInspectionReportsApi } from "../../contexts/siteInspectionReports";
import { createExport } from "../../api/siteInspectionReportApi";

const ExportButton = ({ project }) => {
  const { siteLocation, assessedBy, assessmentType, date, note, exporting } = useSiteInspectionReports()
  const { onInputChange, addToExports } = useSiteInspectionReportsApi()
  const mousePointer = useMemo(() => {
    return exporting || !note ? { pointerEvents: 'none', opacity: 0.75 } : {}
  }, [exporting, note])

  const getExport = () => {
    if (note) {
      onInputChange('exporting', true)
      notifyAlert(
        <p className='ml-1'>
          { 'Please wait, your export is being generated! This will take between 30 - 60 seconds.' }
        </p>,
        { autoClose: 45000, }
      )

      const params = {
        note_id: note.value.id,
        site_location: siteLocation,
        assessed_by: assessedBy,
        assessment_type: assessmentType,
        date: date
      }

      return createExport(params, project)
        .then(res => {
          addToExports([res.data])
          onInputChange('exporting', false)
          const link = document.createElement('a')
          link.href = res.data.file_url
          link.download = `${ note.label }_m_and_m_inspection_report.pdf`
          link.click()
        })
    }

    return notifyError('Please select a note first!')
  }

  return <>
    <button
      className={ `my-6 border text-white bg-regular-blue hover:bg-hover-blue border-regular-blue rounded-md py-2 transition duration-500 select-none text-xl font-bold w-40 text-center` }
      onClick={ getExport }
      style={ mousePointer }
    >
      Export!
    </button>
  </>
}

export default ExportButton
