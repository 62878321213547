import { isEmpty } from "lodash"
import React, { useEffect, useState } from "react"
import Select from "react-select"
import { useTimesheetApi, useTimesheetContext } from '../../contexts/timesheetContext'

const UserSelect = () => {
  const { users } = useTimesheetContext()
  const { updateSelectedUsers, getUserEntries } = useTimesheetApi()
  const [userOptions, setUserOptions] = useState([])
  const onClickHandler = () => getUserEntries()

  useEffect(() => {
    const formattedOptions = users?.map((user) => {
      const userLabel = <span className='font-bold'>{user[1]}</span>
      return { value: user[0], label: userLabel }
    })
    setUserOptions(formattedOptions)
  }, [users])
  
  return (!isEmpty(users) && <div>
    <h1 className='font-semibold'>Select Other User Timesheets:</h1>
    <div className='grid grid-cols-4 mx-3 my-3'>
      <Select
        isMulti
        options={userOptions}
        onChange={(selectedOption) => {
          const users = selectedOption.map(option => option.value)
          updateSelectedUsers(users)
        }}
        className={"w-4/5"}
      />
      <button
        className={`border text-white bg-regular-blue hover:bg-hover-blue border-regular-blue rounded-md px-2 py-1 transition duration-500 select-none text-xl font-bold w-64 text-center`}
        onClick={onClickHandler}
      >
        Get Users Timesheets
      </button>
    </div>
  </div>)
}

export default UserSelect
