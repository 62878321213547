import React, { useCallback, useMemo, useReducer, useState } from "react"
import { toggleReducer } from "../../utilities/helpers"
import axios from "axios"
import { notifyAlert, notifyError } from "../shared/notice"
import { isEmpty } from "lodash"
import { emailQgisReport } from "../../api/emailApi"
import RenderModal from "../modal/renderModal"
import EmailModal from "../modal/emailModal"

const GisPdfButtonRow = ({ formDatum, gisPdfTemplate, buttonNumber }) => {
  const [generatingReport, setGeneratingReport] = useState(false)
  const [emailModal, toggleEmailModal] = useReducer(toggleReducer, false)

  const buttonText = useMemo(() => {
    return [gisPdfTemplate.name, buttonNumber ? `#${ buttonNumber }` : null].filter(i => i).join(' ')
  }, [generatingReport, buttonNumber, gisPdfTemplate])

  const emailSubject = useMemo(() => gisPdfTemplate?.name, [gisPdfTemplate])

  const openPdf = useCallback(async () => {
    setGeneratingReport(true)
    await axios.get(`/form_datum/${ formDatum.id }/gis-pdfs/${ gisPdfTemplate.id }.json`)
      .then(res => window.open(res.data, 'noopener,noreferrer'))
      .then(() => setGeneratingReport(false))
      .catch(error => {
        console.error("ERROR::::: ", error)
        notifyError("There was an error generating the PDF. Please try again.")
      })
  }, [gisPdfTemplate])

  const sendEmail = useCallback(async ({ selectedEmailOptions, subject, body }) => {
    if (isEmpty(selectedEmailOptions)) return notifyError('No recipients selected')
    const params = { recipients: selectedEmailOptions.map(email => email.value), subject, body }

    return emailQgisReport({ gis_pdf_template_id: gisPdfTemplate.id, form_datum_id: formDatum.id, params })
      .then(() => {
        toggleEmailModal(false)
        notifyAlert('Email successfully sent')
      })
      .catch(() => notifyError('Email failed to send'))
  }, [formDatum, gisPdfTemplate])

  return (
    <div className="flex flex-row justify-between gap-4">
      <button
        className="btn btn-primary font-semibold mx-4 my-4 relative cursor-pointer z-10 w-full"
        onClick={ () => openPdf(formDatum.id) }
      >
        { `${ generatingReport ? 'Generating' : 'View' } ${ buttonText }` }
      </button>
      <button
        className="btn btn-primary font-semibold mx-4 my-4 relative cursor-pointer z-10 w-full"
        onClick={ toggleEmailModal }
      >
        Email { buttonText }
      </button>
      <RenderModal>
        { emailModal && (
          <EmailModal modal={ emailModal } fileName="" title="Send QGIS Report" emailSubject={ emailSubject }
                      emailBody="" onClose={ toggleEmailModal } onSend={ sendEmail }/>
        ) }
      </RenderModal>
    </div>
  )
}

export default GisPdfButtonRow
