import React from "react"
import { useChecklistAPI } from "../../../contexts/checklistContext"

const NewChecklistButton = () => {
  const { toggleModal } = useChecklistAPI()
  return (<>
    <button
      className="btn btn-primary font-semibold relative cursor-pointer z-10 w-full"
      onClick={() => toggleModal(('projectChecklistCreationModal'))}
    >
      Create a new Checklist
    </button>
  </>
  )
}

export default NewChecklistButton
