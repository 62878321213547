import React from 'react'
import { useProjectContext } from '../../contexts/projectContext'
import BreadCrumbs from '../projects/nav/breadCrumbs'

const MobileRecordHeaders = () => {
  const { portfolio, project } = useProjectContext()

  return (
    <div className='flex flex-row justify-between'>
      <BreadCrumbs portfolio={portfolio} project={project} />
    </div>
  )
}

export default MobileRecordHeaders
