import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { format } from 'date-fns'
import { isEmpty } from "lodash"
import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import { useChildRecordApi, useChildRecordContext } from "../../../contexts/childRecordContext"
import { useMobileRecordApi } from "../../../contexts/mobileRecordContext"
import Modal from "../../shared/modal"
import Revisions from "../../shared/revisions"

const EntryValue = ({ entry }) => {
  const { updateEntry: updateMobileEntry } = useMobileRecordApi()
  const { mobileRecord } = useChildRecordContext()
  const childApi = useChildRecordApi() // Always call the hook
  
  // Determine if we should use the child API based on mobileRecord
  const updateChildEntry = !isEmpty(mobileRecord) && childApi ? childApi.updateEntry : null
  const updateEntry = updateChildEntry || updateMobileEntry
  
  const valueString = entry.data_type === "GeoJSON"
    ? entry.value.coordinates.join(', ')
    : Array.isArray(entry.value)
      ? entry.value.join(', ')
      : entry.value;

  const [value, setValue] = useState(entry.value ? entry.value : '')
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const updateCoordinate = (index, newValue) => {
    setValue(prevValue => ({
      ...prevValue,
      coordinates: prevValue.coordinates.map((coord, i) =>
        i === index ? parseFloat(newValue) : coord
      )
    }))
  }

  const openModal = () => {
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  const submitEntry = (entry, newValue) => {
    updateEntry(({ ...entry, value: newValue }))
    closeModal()
  }

  const renderInput = () => {
    switch (entry.data_type) {
      case 'textInput':
      case 'String':
        return (
          <textarea
            className="resize max-w-full w-full"
            value={value}
            onChange={e => setValue(e.target.value)}
          />
        )
      case 'GeoJSON':
        return (
          <>
            <input
              type="number"
              step="any"
              value={value.coordinates[0]}
              onChange={e => updateCoordinate(0, e.target.value)}

              className="block w-full p-1 text-xs text-gray-700 appearance-none focus:outline-none border border-gray-200 focus:shadow-inner m-0 rounded"
            />
            <input
              type="number"
              step="any"
              value={value.coordinates[1]}
              onChange={e => updateCoordinate(1, e.target.value)}

              className="block w-full p-1 text-xs text-gray-700 appearance-none focus:outline-none border border-gray-200 focus:shadow-inner m-0 rounded"
            />
          </>
        )
      case 'Number':
        return (
          <input
            type="number"
            step="any"
            value={value}
            onChange={e => setValue(e.target.value)}
            className="block w-full p-1 text-xs text-gray-700 appearance-none focus:outline-none border border-gray-200 focus:shadow-inner m-0 rounded"
          />
        )
      case 'DateTime': {
        // Convert the string value to a Date object for the DatePicker component
        const parsedValue = value ? new Date(value) : null
        return (
          <DatePicker
            className="block w-full p-1 text-xs text-gray-700 appearance-none focus:outline-none border border-gray-200 focus:shadow-inner m-0 rounded z-1000"
            selected={parsedValue}
            onChange={(date) => {
              setValue(format(date, 'yyyy-MM-dd'))
            }}
          />
        )
      }
      default:
        return <div>{valueString}</div>
    }
  }

  // Need to add revisions to be seen 


  return (
    <div className={`w-40 z-10 flex flex-row border border-gray-300 `}>
      {entry && <>
        <div
          className='flex flex-row items-center justify-between gap-x-2 cursor-pointer group hover:bg-blue-100 p-2 border-gray-300 w-full h-full'
          onClick={openModal}>
          <div className='h-4 whitespace-nowrap truncate text-xs'>{valueString}</div>
          <FontAwesomeIcon
            className='text-xs opacity-0 group-hover:opacity-100 text-gray-600'
            icon={faEdit}
          />
        </div>
        {modalIsOpen &&
          <Modal closeModal={closeModal} heading={entry.label} onSave={() => submitEntry(entry, value)}>
            <div className='w-full flex flex-col lg:flex-row lg:justify-between gap-y-4 lg:gap-y-0 lg:gap-x-4'>
              <div className='w-full max-w-[80%] relative'>{renderInput()}</div>
              <div className='w-full'>
                {entry.versions ? <Revisions formEntry={entry} /> : <></>}
              </div>
            </div>
          </Modal>}
      </>}
    </div>
  )
}

export default EntryValue

