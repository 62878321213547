import React from 'react'
import { useTimeTableApi } from '../../../contexts/timeTableContext'
import CheckBoxField from '../../shared/checkBoxField'

const ApprovalButton = ({ dayEntry }) => {
  const { updateDayEntry } = useTimeTableApi()
  return (
    <CheckBoxField checked={dayEntry.approved} setChecked={(value) => updateDayEntry(dayEntry.id, { approved: value })} 
    className="w-full h-full flex items-center justify-center" />
  )
}

export default ApprovalButton
