import React from "react"
import { blankEditEntry, useTimeTableApi } from "../../../contexts/timeTableContext"
import StandardButton from "../../shared/standardButton"

const TimesheetNewEntryButton = () => {
  const { toggleState, updateState } = useTimeTableApi()
  const onClickHandler = () => updateState('editEntry', blankEditEntry) || toggleState('editModal')

  return <StandardButton buttonText="Add New Entry" onClickHandler={onClickHandler} />
}

export default TimesheetNewEntryButton
