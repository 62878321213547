import { parseDateString } from "./dateHelpers"

export const checklistStatusOptions = [
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
  { label: "OnHold", value: "OnHold" },
  { label: "Completed", value: "Completed" },
  { label: "Cancelled", value: "Cancelled" },
  { label: "Deactivate Checklist", value: "Deactivate Checklist" }
]

export const updateEntryAttribute = (checklistEntryObject, project_checklist_id, checklistColumn, newAttribute) => {
  return {
    ...checklistEntryObject,
    [project_checklist_id]: checklistEntryObject[project_checklist_id].map(entry => {
      if (entry.id === newAttribute.checklist_entry_id) {
        const hasMatchingColumn = entry.checklist_attributes.some(attribute =>
          attribute.checklist_column_id === checklistColumn?.id)

        const updatedAttributes = entry.checklist_attributes.map(attribute =>
          attribute.id === newAttribute.id ? newAttribute : attribute)

        if (!hasMatchingColumn && checklistColumn) { updatedAttributes.push(newAttribute) }

        return {
          ...entry,
          checklist_attributes: updatedAttributes,
          last_attribute_version: newAttribute.versions.slice(-1)[0],
          column_updated: checklistColumn?.title
        }
      }
      return entry
    })
  }
}

export const deleteChecklistAttribute = (checklistEntryObject, project_checklist_id, attributeId) => {
  return {
    ...checklistEntryObject,
    [project_checklist_id]: checklistEntryObject[project_checklist_id].map(entry => {
        const updatedAttributes = entry.checklist_attributes.filter(attribute => attribute.id !== attributeId)
        return { ...entry, checklist_attributes: updatedAttributes }
      }
    )
  }
}

export const updateProjectChecklists = (project_checklists, updated_project_checklist) => {
  const updated_checklists = project_checklists.map(projectChecklist => {
    if (projectChecklist.id === updated_project_checklist.id) {
      return updated_project_checklist
    }
    return projectChecklist
  })
  return updated_checklists
}

export const updateCollapsedArray = (project_checklist_id, collapsedChecklists) => {
  if (collapsedChecklists.includes(project_checklist_id)) {
    return collapsedChecklists.filter(id => id !== project_checklist_id);
  } else {
    return [...collapsedChecklists, project_checklist_id]
  }
}

export const getValueByFilter = (project_checklists, filter) => {
  const valuesWithChecklistId = project_checklists.map((checklist) => {
    if (filter === 'date') {
      return { label: parseDateString(checklist[filter]).toDateString(), value: checklist.id }
    } else {
      return { label: checklist[filter], value: checklist.id }
    }
  })
  const reducedValuesWithChecklistId = groupSameValues(valuesWithChecklistId)
  const sortedValuesWithChecklistId = reducedValuesWithChecklistId.sort(compareLabels)

  return sortedValuesWithChecklistId
}

export const getChecklistIds = (valuesWithChecklistIds, valueFilter) => {
  return valuesWithChecklistIds
    .filter(option => option.label === valueFilter)
    .map(option => option.value)
}

const groupSameValues = (array) => {
  const groupedValues = array.reduce((groupedValues, item) => {
    const { label, value } = item
    return { ...groupedValues, [label]: [...(groupedValues[label] || []), value] }
  }, {})

  return Object.keys(groupedValues).map(label => {
    return { "label": label, "value": groupedValues[label] }
  })
}

const compareLabels = (a, b) => {
  if (a.label < b.label) {
    return -1
  } else if (a.label > b.label) {
    return 1
  } else {
    return 0
  }
}

export const updateToggleModal = (state, action) => {
  const updatedToggle = { ...state, [action]: !state[action] }
  return updatedToggle
}

export const stringifyRevision = (revision) => {

  if (revision) {
    const { created_at, created_by, value } = revision
    const { firstName, lastName, email, defined } = revisionUnknowns(created_by)
    const created_by_name = [firstName, lastName, email].join(' ')
    const date = parseDateString(created_at).toDateString()
    const updatedValue = isDateString(value) ? parseDateString(value).toDateString() : value
    return { created_by_name, updatedValue, date, defined }
  } else {
    return { created_by_name: 'N/A', updatedValue: 'N/A', date: 'N/A', defined: false }
  }
}

const revisionUnknowns = (created_by) => {
  return {
    firstName: created_by?.first_name || "",
    lastName: created_by?.last_name || "",
    ...(created_by?.first_name || created_by?.last_name ? { email: "" } : { email: created_by?.email }),
    ...(created_by?.first_name || created_by?.last_name || created_by?.email ? { defined: true } : { defined: false }),
    ...(created_by?.first_name || created_by?.last_name ? { email: "" } : { email: created_by?.email }),
    ...(created_by?.first_name || created_by?.last_name || created_by?.email ? { defined: true } : { defined: false }),
  }
}

const isDateString = (inputString) => {
  const timestamp = Date.parse(inputString);
  return !isNaN(timestamp);
}

export const updateEntry = (checklistEntryObject, project_checklist_id, updatedEntry) => {
  return {
    ...checklistEntryObject,
    [project_checklist_id]: checklistEntryObject[project_checklist_id].map(entry => {
      return entry.id === updatedEntry.id ? updatedEntry : entry
    })
  }
}

