import { faChartBar, faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from 'react'
import { useNotesContext } from '../../contexts/notesContext'
import ExportCsvData from "../admin/projects/exportCsvData"
import ExportButton from "./exportButton"

const DefaultHeader = () => {
  const { notes, routes, project } = useNotesContext()

  const hasSampleData = () => {
    return notes && notes.filter((note) => note.has_sample_data).length > 0
  }

  return (
    <>
      {hasSampleData() && (
        <>
          <a href={routes.boreholeDepthChartPath} className='btn btn-primary' target="_blank">
            <FontAwesomeIcon icon={faChartBar} className='mr-2' />
            Sample Chart
          </a>
          <a href={routes.exportCsvPath} className='btn btn-primary' target="_blank">
            <FontAwesomeIcon icon={faFilePdf} className='mr-2' />
            Export CoC
          </a>
        </>
      )}
      {routes?.exportForemanReportPath &&
        <ExportButton link={routes?.exportForemanReportPath}>Foreman Daily</ExportButton>}
      {routes?.exportUpiReportPath &&
        <ExportButton link={routes?.exportUpiReportPath}>Upi</ExportButton>}
      {routes?.exportGradingReportPath &&
        <ExportButton link={routes?.exportGradingReportPath}>Grading</ExportButton>}
      {routes?.exportWeldingProgressReportPath &&
        <ExportButton link={routes?.exportWeldingProgressReportPath}>Welding Progress</ExportButton>}
      {routes?.exportTrenchingReportPath &&
        <ExportButton link={routes?.exportTrenchingReportPath}>Trenching</ExportButton>}
      {routes?.exportForeignFacilityCrossingBackfillReportPath &&
        <ExportButton link={routes?.exportForeignFacilityCrossingBackfillReportPath}>Foreign Facility Crossing Backfill</ExportButton>}
      {routes?.exportLoweringInReportPath &&
        <ExportButton link={routes?.exportLoweringInReportPath}>Lowering In</ExportButton>}
      {routes?.exportFieldCoatingDftHolidayReportPath &&
        <ExportButton link={routes?.exportFieldCoatingDftHolidayReportPath}>Field Coating DFT Holiday</ExportButton>}
      {routes?.exportHydrovacLocateReportPath &&
        <ExportButton link={routes?.exportHydrovacLocateReportPath}>Hydrovac Locate</ExportButton>}
      {routes?.exportHydrovacLocateReportPath &&
        <ExportButton link={routes?.exportFieldCoatingMaterialsAndInspectionReportPath}>Field Coating Materials & Inspection</ExportButton>}
      {routes?.exportBackfillReportPath &&
        <ExportButton link={routes?.exportBackfillReportPath}>Backfill</ExportButton>}
      {routes?.exportDemoReportPath &&
        <ExportButton link={routes?.exportDemoReportPath}>Demo</ExportButton>}
      {routes?.exportProdeltaDailySummaryReportPath &&
        <ExportButton link={routes?.exportProdeltaDailySummaryReportPath}>ProDelta Daily Summary</ExportButton>}
      {routes?.exportFieldDailyReportPath &&
        <ExportButton link={routes?.exportFieldDailyReportPath}>Field Daily</ExportButton>}
      {routes?.boreholeLogExportPath && <a className="btn btn-primary" href={routes?.boreholeLogExportPath} target="_blank">
        <FontAwesomeIcon icon={faFilePdf} className="mr-2" />
        Export Borehole Logs
      </a>}
      <ExportCsvData routes={routes} project={project} />
    </>
  )
}

export default DefaultHeader
