import React, { useMemo } from "react"
import AiPluginLink from "./aiPluginLink"
import ProfileNav from "./profileNav"

const TopNav = ({ adminPath, destroyUserSessionPath, user }) => {
  const { organization } = user || {}
  const timesheetEnabled = useMemo(() => {
    return user?.organization?.timesheet_enabled && user?.organization?.timesheet_template_id
  }, [organization])

  const safetyTicketEnabled = useMemo(() => {
    return user?.organization?.safety_ticket_enabled
  }, [organization])

  return <>
    <div
      className="sidenav order-last w-full flex flex-row items-center bg-gray-900 shadow-lg print:hidden lg:order-first z-10">
      <div className="items-center h-auto hidden lg:flex">
        <a href="/projects">
          <img src="/field_dms_logo.png" alt="Prodelta Logo" className="h-16"/>
        </a>
      </div>
      <nav className="flex flex-row lg:justify-between">
        <div className="flex flex-row items-center justify-between lg:justify-start lg:gap-x-4 lg:pl-4 w-1/2">
          <a href="/portfolios">
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="archive" role="img"
                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path fill="currentColor"
                    d="M464 32H48C21.5 32 0 53.5 0 80v80c0 8.8 7.2 16 16 16h16v272c0 17.7 14.3 32 32 32h384c17.7 0 32-14.3 32-32V176h16c8.8 0 16-7.2 16-16V80c0-26.5-21.5-48-48-48zm-32 400H80V176h352v256zm32-304H48V80h416v48zM204 272h104c6.6 0 12-5.4 12-12v-24c0-6.6-5.4-12-12-12H204c-6.6 0-12 5.4-12 12v24c0 6.6 5.4 12 12 12z"></path>
            </svg>
            <span className="hidden lg:flex">Portfolios</span>
          </a>
          <a href="/projects">
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="archive" role="img"
                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path fill="currentColor"
                    d="M464 32H48C21.5 32 0 53.5 0 80v80c0 8.8 7.2 16 16 16h16v272c0 17.7 14.3 32 32 32h384c17.7 0 32-14.3 32-32V176h16c8.8 0 16-7.2 16-16V80c0-26.5-21.5-48-48-48zm-32 400H80V176h352v256zm32-304H48V80h416v48zM204 272h104c6.6 0 12-5.4 12-12v-24c0-6.6-5.4-12-12-12H204c-6.6 0-12 5.4-12 12v24c0 6.6 5.4 12 12 12z"></path>
            </svg>
            <span className="hidden lg:flex">Projects</span>
          </a>
        </div>
        <div className="flex flex-row items-center gap-x-2">
          { user && <ProfileNav user={ user }/> }
          { user && timesheetEnabled && <a href="/timesheets">Timesheet</a> }
          { user && <a href="/safety_tickets">Safety Tickets</a> }
          { user && <a href="/training-tracker">Training Tracker</a> }
          { user && <AiPluginLink user={ user }/> }
          <a href={ adminPath } target="_blank">Admin</a>
          <a href={ destroyUserSessionPath } data-method="delete" rel="nofollow">
            <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="sign-out" className="text-red-400"
                 role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <g className="fa-group">
                <path className="fa-secondary" fill="currentColor"
                      d="M180 448H96a96 96 0 0 1-96-96V160a96 96 0 0 1 96-96h84a12 12 0 0 1 12 12v40a12 12 0 0 1-12 12H96a32 32 0 0 0-32 32v192a32 32 0 0 0 32 32h84a12 12 0 0 1 12 12v40a12 12 0 0 1-12 12z"
                      opacity="0.4"></path>
                <path className="fa-primary" fill="currentColor"
                      d="M353 88.3l151.9 150.6a24 24 0 0 1 0 34.1l-152 150.8a24.08 24.08 0 0 1-33.9-.1l-21.9-21.9a24.07 24.07 0 0 1 .8-34.7l77.6-71.1H184a23.94 23.94 0 0 1-24-24v-32a23.94 23.94 0 0 1 24-24h191.5l-77.6-71.1a24 24 0 0 1-.7-34.6l21.9-21.9a24 24 0 0 1 33.9-.1z"></path>
              </g>
            </svg>
            <span className="hidden lg:flex">Logout</span>
          </a>
        </div>
      </nav>
    </div>
  </>
}

export default TopNav
