import React from 'react'
import ProjectSideBar from "../projects/project/projectSideBar"
import NoteFilters from "../projects/shared/NoteFilters"
import Widget from "../shared/widget"

const ConstructionLayout = ({ children, project, routes, showMap, showFormNameFilter }) => {

  return (
    <div className="flex flex-col flex-grow overflow-hidden lg:flex-row max-h-[calc(100vh - 161px)]">
      <ProjectSideBar project={project} routes={routes} />
      <div
        className={`flex flex-col w-full h-2/3 flex-grow overflow-scroll gap-y-1 ${showMap && 'lg:h-full lg:w-3/4'}`}>
        <Widget project={project} widgetProp={project?.default_widget?.full_url} />
        <div>
          {showFormNameFilter() && <NoteFilters />}
          {children}
        </div>
      </div>
    </div>
  )
}

export default ConstructionLayout
