import { faFilePdf } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useNotesContext } from "../../contexts/notesContext"
import { useProjectContext } from "../../contexts/projectContext"
import EnableSyncCheckBox from "../admin/projects/enableSyncCheckBox"
import BreadCrumbs from "../projects/nav/breadCrumbs"
import SyncedAt from "../projects/syncedAt"
import DefaultHeader from "./defaultHeader"
import SyncNotesButton from "./syncNotesButton"

const NotesHeader = () => {
  const { portfolio, portfolioType } = useProjectContext()
  const { routes, project } = useNotesContext()
  const inspectionReportLink = `/projects/${project.id}/site_inspection_reports`

  return (
    <div className='flex flex-row justify-between'>
      <BreadCrumbs portfolio={portfolio} project={project} routes={routes} />
      <div className='actions flex items-center flex gap-x-2 mr-2'>
        <EnableSyncCheckBox outerClass={'flex flex-row space-x-2 items-center mr-4'}>
          Enable Sync?
        </EnableSyncCheckBox>
        <SyncedAt />
        {project.name === 'Petronas_Impact' &&
          <a className="btn btn-primary" href={inspectionReportLink} target="_blank">
            <FontAwesomeIcon icon={faFilePdf} className="mr-2" />
            MM Inspection Reports
          </a>
        }
        <SyncNotesButton />
        {!portfolioType || portfolioType !== "Construction" && <DefaultHeader />}
      </div>
    </div>
  )
}

export default NotesHeader
