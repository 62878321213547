import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { ChecklistProvider } from '../contexts/checklistContext'
import { DocumentBuilderProvider } from "../contexts/documentBuilderContext"
import { DocumentLogProvider } from "../contexts/documentLogContext"
import { MobileRecordsProvider } from '../contexts/mobileRecordsContext'
import { NoteFilterProvider } from "../contexts/noteFilterContext"
import { NotesProvider } from '../contexts/notesContext'
import { PermitProvider } from '../contexts/permitContext'
import { PhotoProvider } from '../contexts/photoContext'
import { PortfolioProvider } from "../contexts/portfolioContext"
import { ProjectProvider } from "../contexts/projectContext"
import { ReportProvider } from "../contexts/reportContext"
import { TrackerProvider } from "../contexts/trackerContext"
import { UserProvider } from '../contexts/userContext'
import AiProjectDocument from './ai/projectDocuments'
import Checklist from './checklists'
import DocumentBuilder from "./documentBuilder"
import EditDocumentBuilder from "./documentBuilder/edit"
import DocumentLog from "./documentLog"
import HistoricalAssessment from "./historicalAssessment"
import LabAnalytics from './labAnalytics'
import AnalyticSummary from './labAnalytics/analyticSummary'
import TestResults from './labAnalytics/testResults'
import MobileRecords from './mobileRecords'
import MobileRecord from './mobileRecords/mobileRecord'
import Notes from "./notes"
import BoreholeScatterChart from './notes/charts/boreholeScatterChart'
import PhotoGallery from './photoGallery'
import Export from "./projects/exportContext"
import Note from "./projects/note"
import Permits from "./projects/permits"
import Permit from "./projects/permits/permit"
import PortfolioProject from "./projects/project"
import Report from "./reports"
import ReportsExport from "./reports/reports-export"
import SafetyTicket from './safetyTicket'
import LandingPage from "./shared/landingPage"
import Timesheet from "./timesheet"
import Tracker from "./trackers"
import TrackerChangeLog from "./trackers/changeLog"
import TrainingTracker from "./trainingTracker"
import UserLandingPage from "./user"

export default function MainProject({ project, routes }) {
  return (
    <Router>
      <UserProvider>
        <Routes>
          <Route
            path='/projects/:projectId/notes/:noteId'
            element={
              <>
                {
                  project && project.gen5 ?
                    <ProjectProvider project={project} routes={routes}>
                      <MobileRecordsProvider>
                        <MobileRecords>
                          <MobileRecord />
                        </MobileRecords>
                      </MobileRecordsProvider>
                    </ProjectProvider>
                    :
                    <ProjectProvider project={project} routes={routes}>
                      <NotesProvider project={project} routes={routes}>
                        <NoteFilterProvider>
                          <Notes>
                            <Note />
                          </Notes>
                        </NoteFilterProvider>
                      </NotesProvider>
                    </ProjectProvider>
                }
              </>
            }
          />
          <Route
            path='/projects/:projectId/notes'
            element={
              <ProjectProvider project={project} routes={routes}>
                {
                  project && project.gen5 ?
                    <MobileRecordsProvider>
                      <MobileRecords />
                    </MobileRecordsProvider>
                    :
                    <NotesProvider project={project} routes={routes}>
                      <NoteFilterProvider>
                        <Notes />
                      </NoteFilterProvider>
                    </NotesProvider>
                }
              </ProjectProvider>
            }
          />
          <Route
            path='/projects/:projectId/permits/:permitId'
            element={
              <ProjectProvider project={project} routes={routes}>
                <Permit routes={routes} />
              </ProjectProvider>
            }
          />
          <Route
            path='/projects/:projectId/permits'
            element={
              <ProjectProvider project={project} routes={routes}>
                <PermitProvider routes={routes}>
                  <Permits />
                </PermitProvider>
              </ProjectProvider>
            }
          />
          <Route
            path='/projects/:projectId/landing_page'
            element={
              <Export project={project} routes={routes}>
                <LandingPage project={project} routes={routes} />
              </Export>
            }
          />
          <Route
            path='/projects/:projectId/historical-assessment'
            element={<HistoricalAssessment project={project} />} />
          <Route
            path='/projects/:projectId/trackers/:trackerSlug/change-log'
            element={
              <ProjectProvider project={project} routes={routes}>
                <PortfolioProvider>
                  <TrackerProvider>
                    <TrackerChangeLog routes={routes} />
                  </TrackerProvider>
                </PortfolioProvider>
              </ProjectProvider>
            }
          />
          <Route
            path='/projects/:projectId/trackers/:trackerSlug'
            element={
              <ProjectProvider project={project} routes={routes}>
                <PortfolioProvider>
                  <TrackerProvider>
                    <Tracker routes={routes} />
                  </TrackerProvider>
                </PortfolioProvider>
              </ProjectProvider>
            }
          />
          <Route
            path='/projects/:projectId/checklists/:checklistSlug'
            element={
              <ProjectProvider project={project} routes={routes}>
                <PortfolioProvider>
                  <ChecklistProvider>
                    <Checklist routes={routes} />
                  </ChecklistProvider>
                </PortfolioProvider>
              </ProjectProvider>
            }
          />
          <Route
            path='/projects/:projectId/reports/:reportSlug'
            element={
              <ProjectProvider project={project} routes={routes}>
                <PortfolioProvider>
                  <ReportProvider>
                    <Report routes={routes} />
                  </ReportProvider>
                </PortfolioProvider>
              </ProjectProvider>
            }
          />
          <Route
            path='/projects/:projectId/reports-export'
            element={
              <ProjectProvider project={project} routes={routes}>
                <PortfolioProvider>
                  <ReportsExport routes={routes} />
                </PortfolioProvider>
              </ProjectProvider>
            }
          />
          <Route
            path='/projects/:projectId/document-log'
            element={
              <ProjectProvider project={project} routes={routes}>
                <DocumentLogProvider>
                  <DocumentLog />
                </DocumentLogProvider>
              </ProjectProvider>
            } />
          <Route
            path='/projects/:projectId/document-builders/:templateSlug/document-log/:documentId'
            element={
              <ProjectProvider project={project} routes={routes}>
                <DocumentBuilderProvider>
                  <DocumentBuilder />
                </DocumentBuilderProvider>
              </ProjectProvider>
            } />
          <Route
            path='/projects/:projectId/document-builders/:templateSlug/document-log/:documentId/edit'
            element={
              <ProjectProvider project={project} routes={routes}>
                <DocumentBuilderProvider>
                  <EditDocumentBuilder />
                </DocumentBuilderProvider>
              </ProjectProvider>
            } />
          <Route
            path='/projects/:projectId'
            element={
              <ProjectProvider project={project} routes={routes}>
                <NoteFilterProvider>
                  <PortfolioProject project={project} routes={routes} />
                </NoteFilterProvider>
              </ProjectProvider>
            }
          />
          <Route
            path='/projects/:projectId/borehole_depth_chart'
            element={
              <ProjectProvider project={project} routes={routes}>
                <BoreholeScatterChart routes={routes} />
              </ProjectProvider>
            }
          />
          <Route
            path='/projects/:projectId/photo_gallery'
            element={
              <PhotoProvider>
                <PortfolioProvider>
                  <PhotoGallery />
                </PortfolioProvider>
              </PhotoProvider>
            }
          />
          <Route
            path='/projects/:projectId/ai/documents'
            element={
              <ProjectProvider project={project} routes={routes}>
                <PortfolioProvider>
                  <AiProjectDocument routes={routes} />
                </PortfolioProvider>
              </ProjectProvider>
            }
          />
          <Route
            path='projects/:projectId/sample-collections'
            element={<LabAnalytics project={project} routes={routes} />}
          />
          <Route
            path='projects/:projectId/analytic-exports/:analyticExportId'
            element={<TestResults project={project} routes={routes} />}
          />
          <Route
            path='projects/:projectId/analytic-exports/:analyticExportId/summary'
            element={<AnalyticSummary project={project} routes={routes} />}
          />
          <Route path='/timesheets' element={<Timesheet />} />
          <Route path='/users/landing_page' element={<UserLandingPage />} />
          <Route path='/safety_tickets' element={<SafetyTicket />} />
          <Route path='/training-tracker' element={<TrainingTracker />} />
        </Routes>
      </UserProvider>
    </Router>
  )
}
