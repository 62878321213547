import { directedFlatten, optionize, pipe } from './utils'
import { parseDateString } from "./dateHelpers"


const filterAttributes = (options) => {
  const uniqueOptionsSet = new Set()
  return options?.filter(option => {
    let isPresentInSet
    if (option.label !== null && option.label !== undefined) {
      isPresentInSet = uniqueOptionsSet.has(option.label)
      uniqueOptionsSet.add(option.label)
      return !isPresentInSet
    }
  })
}

const removeStringFromLabel = (string) => (inputArray) => inputArray.map(element => {
  return { label: element?.label?.replace(string, ''), value: element?.value }
})

export const initializeState = (notes) => {

  const formNames = pipe(
    directedFlatten('form_data'),
    directedFlatten('form_template'),
    optionize('name', 'id'),
    filterAttributes,
  )(notes)

  const foremanNameList = pipe(
    optionize('foreman_name', 'foreman_name'),
    removeStringFromLabel('User-'),
    filterAttributes,
  )(notes)

  const activityList = pipe(optionize('activity', 'activity'), filterAttributes)(notes)

  const dateList = notes.map(note => parseDateString(note.date))

  return {
    formTemplateNameList: formNames,
    foremanNameList: foremanNameList,
    activitiesList: activityList,
    dateList: dateList,
  }
}
