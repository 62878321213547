import React, { useEffect } from 'react'
import { usePortfolioAPI, usePortfolioContext } from "../../contexts/portfolioContext"
import ProjectSideBar from "../../components/projects/project/projectSideBar"
import WidgetContainer from "./widgetContainer"
import ToggleWidgetButtons from "./toggleWidgetButtons"

const ProjectView = ({ children, routes, WidgetComponentReplacement, showWidget = true, buttonName = null }) => {
  const { project, widget, defaultWidget } = usePortfolioContext()
  const { updateWidget, toggleWidget } = usePortfolioAPI()

  useEffect(() => {
    if (defaultWidget) updateWidget(defaultWidget?.full_url)
  }, [defaultWidget])

  return <>
    { project && <ProjectSideBar project={ project } routes={ routes }/> }
    <div className='flex flex-col w-3/4 h-full overflow-y-auto'>
      { showWidget && <WidgetContainer WidgetComponentReplacement={ WidgetComponentReplacement }/> }
      { showWidget && <ToggleWidgetButtons toggleWidget={ toggleWidget } widget={ widget } buttonName={ buttonName }/> }
      { children }
    </div>
  </>
}

export default ProjectView
