import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getRecord } from '../../api/mobile/recordsApi'
import { MobileRecordProvider } from '../../contexts/mobileRecordContext'
import BoreholeDetails from './mobileRecordDisplays/boreholeDetails'
import ChildRecords from './mobileRecordDisplays/childRecords'

const MobileRecord = () => {
  const [mobileRecord, setMobileRecord] = useState()
  const { projectId, noteId } = useParams()

  useEffect(() => {
    noteId && getRecord(projectId, noteId).then(res => {
      setMobileRecord(res)
    })
  }, [noteId])
  // Add to mobile record context

  return (
    mobileRecord ?
      <MobileRecordProvider mobileRecord={mobileRecord}>
        <div className='flex flex-row flex-1 bg-gray-100 h-full'>
          {mobileRecord.record_type === 'Borehole' ? <BoreholeDetails > <ChildRecords /> </BoreholeDetails> : <ChildRecords />}
        </div>
      </MobileRecordProvider> : <></>
  )
}
export default MobileRecord
