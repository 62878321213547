import { isEmpty } from "lodash"
import { directedFlatten, optionize, pipe } from "./utils"
import { isValid } from "date-fns"
import { parseDateString } from "./dateHelpers"

export const cellColors = {
  white: '#ffffff',
  red: '#f87171',
  orange: '#fdba74',
  yellow: '#fef9c3',
  green: '#d9f99d',
  blue: '#bae6fd'
}

export const specialHeaders = ['Duplicate', 'Zoom To Location', 'Delete']

/**
 * Maps through entries in the array. When the entry with the same entryId is found, filters out the attributes that
 * don't have the id of the new attribute. Then adds the new attribute to the end of the array.
 * This effectively updates the attribute if it already exists or adds a new attribute if it doesn't.
 * @param trackerEntryArray - Array of tracker entries
 * @param entryId - Entry that holds the attribute we're editing
 * @param newAttribute - Attribute we're adding or updating
 * @returns {*}
 */
export const updateEntryAttribute = (trackerEntryArray, entryId, newAttribute) => {
  return trackerEntryArray.map(entry => {
    if (entry.id !== entryId) return entry
    const newAttributeArray = entry.tracker_attributes.filter(attribute => attribute.id !== newAttribute.id)
    return { ...entry, tracker_attributes: [...newAttributeArray, newAttribute] }
  })
}

export const deleteEntryAttribute = (trackerEntryArray, entryId, attributeId) => {
  return trackerEntryArray.map(entry => {
      if (entry.id !== entryId) return entry
      const newAttributeArray = entry.tracker_attributes.filter(attribute => attribute.id !== attributeId)
      return { ...entry, tracker_attributes: newAttributeArray }
    }
  )
}

export const updateEntryProject = (trackerEntryArray, newEntry) => {
  return trackerEntryArray.map(entry => {
    return entry.id === newEntry.id ? newEntry : entry
  })
}

export const updateSelectedEntryIds = (selectedEntryIdsArray, entryId, isChecked) => {
  if (isChecked && !selectedEntryIdsArray.includes(entryId)) {
    return [...selectedEntryIdsArray, entryId]
  }
  if (!isChecked && selectedEntryIdsArray.includes(entryId)) {
    return selectedEntryIdsArray.filter((cell) => cell !== entryId)
  }
}

export const addRenderOrder = (headers) => {
  for (let i = 0; i < headers.length; i++) {
    headers[i].renderPosition = i;
  }
  return headers
}

const createFrozenStyle = (entry, leftDistance = entry?.leftDistance, rightDistance = entry?.rightDistance) => ({
  ...entry,
  leftDistance,
  rightDistance,
  headerStyles: {
    left: `${ leftDistance }px`,
    right: `${ rightDistance }px`,
    position: 'sticky',
    zIndex: 20,
  },
  entryStyles: {
    left: `${ leftDistance }px`,
    right: `${ rightDistance }px`,
    position: 'sticky',
    zIndex: -5,
  }
})

export const updateFrozenStylesObject = (frozenStyles, cellWidth, renderPosition, isChecked) => {
  const copiedFrozenStyles = structuredClone(frozenStyles) // Using structuredClone for deep copy

  if (isChecked) {
    copiedFrozenStyles[renderPosition] = { cellWidth: cellWidth }
  } else {
    delete copiedFrozenStyles[renderPosition]
  }

  const lTRKeys = Object.keys(copiedFrozenStyles).sort((a, b) => a - b)
  const rTLKeys = Object.keys(copiedFrozenStyles).sort((a, b) => b - a)

  let leftDistance = 0
  let rightDistance = 0

  return pipe(
    setDistanceAttribute(lTRKeys, 'leftDistance', leftDistance),
    setDistanceAttribute(rTLKeys, 'rightDistance', rightDistance),
    calculateAllDistances
  )(copiedFrozenStyles)
}

const setDistanceAttribute = (keys, property, maintainedValue) => set => {
  return keys.reduce((accumulator, current) => ({
    ...accumulator,
    [current]: {
      ...accumulator[current],
      [property]: -accumulator[current].cellWidth + (maintainedValue += accumulator[current].cellWidth)
    }
  }), set)
}

const calculateAllDistances = set => {
  for (const property in set) {
    set[property] = createFrozenStyle(set[property])
  }
  return set
}

export const updateCollapsedHeaders = (collapsedHeaders, renderPosition, isChecked) => {
  const updatedCollapsedHeaders = [...collapsedHeaders]

  if (isChecked === false) {
    const index = updatedCollapsedHeaders.indexOf(renderPosition)
    if (index !== -1) {
      updatedCollapsedHeaders.splice(index, 1)
    }
  } else if (!updatedCollapsedHeaders.includes(renderPosition)) {
    updatedCollapsedHeaders.push(renderPosition)
  }

  return updatedCollapsedHeaders
}

export const pieChartOptions = (chartData) => {
  return {
    chart: {
      type: 'pie'
    },
    title: {
      text: 'Transport Company Quantities'
    },
    series: [{
      name: 'Quantity',
      colorByPoint: true,
      data: chartData
    }]
  }
}
const getHeader = (headers, headerTitle) => headers?.filter(header => header.title === headerTitle)[0]

const unformattedChartData = (trackerEntries, quantityHeader, companyHeader) => {
  return trackerEntries.map(entry => {
    const companyHash = entry.tracker_attributes.filter(attribute => attribute.tracker_header_id == companyHeader.id)[0]
    const quantityHash = entry.tracker_attributes.filter(attribute => attribute.tracker_header_id == quantityHeader.id)[0]

    return { name: companyHash?.value, value: Number(quantityHash?.value) }
  })
}

export const formattedChartData = (trackerHeaders, trackerEntries) => {
  const quantityHeader = getHeader(trackerHeaders, 'Quantity')
  const companyHeader = getHeader(trackerHeaders, 'Transport Company')

  if (quantityHeader && companyHeader) {
    const rawChartData = unformattedChartData(trackerEntries, quantityHeader, companyHeader)
    const uniqueChartHeaders = [...new Set(rawChartData.map(data => data.name).filter((data => data)))]
    return uniqueChartHeaders.map((header) => {
      const value = rawChartData.filter(data => data.name === header).map(data => data.value).reduce((a, b) => a + b, 0)

      return { name: header, y: value }
    })
  }
}

export const optionizeHeaders = (trackerHeaders) => trackerHeaders?.map(header => ({
  label: header?.title,
  value: { id: header?.id, type: header?.type }
}))

export const filterInnerAttributesForHeader = (headerId) => (inputArray) => inputArray.filter(element => element?.tracker_header_id === headerId)

export const entriesWithHeader = (headerId, headerType = null, value = null) => (entries) => {
  return entries.filter(entry => {
    const attributesWithHeader = entry?.tracker_attributes.filter(attribute => {
      const headerValue = headerType === 'DateHeader' ? parseDateString(attribute?.value).toDateString() : attribute?.value
      return (!value || headerValue === value) && attribute?.tracker_header_id === headerId
    })
    return !isEmpty(attributesWithHeader)
  })
}

const uniqueOptions = inputArray => {
  const uniqueValues = new Set()
  return inputArray.filter(element => {
    if (!uniqueValues.has(element?.value)) return uniqueValues.add(element.value)
  })
}

export const valuesWithHeaderId = (header) => (entries) => {
  return pipe(
    directedFlatten('tracker_attributes'),
    filterInnerAttributesForHeader(header?.id),
    optionize('value', 'value'),
    convertDateValues(header?.type),
    uniqueOptions,
    sortFilterOptions,
  )(entries)
}

export const sortTrackerEntries = (headerId, entries, isAscending) => {
  let sortedEntries = [...entries]

  sortedEntries.forEach((entry) => {
    const trackerAttributes = entry.tracker_attributes.filter(
      (attr) => attr.tracker_header_id === headerId
    )

    const nonMatchingAttributes = entry.tracker_attributes.filter(
      (attr) => attr.tracker_header_id !== headerId
    )

    entry.tracker_attributes = [...trackerAttributes, ...nonMatchingAttributes]
  })

  sortedEntries.sort((a, b) => {
    const valueA = a.tracker_attributes.find(attr => attr.tracker_header_id === headerId)?.value || ''
    const valueB = b.tracker_attributes.find(attr => attr.tracker_header_id === headerId)?.value || ''

    if (!valueA && !valueB) {
      return 0
    } else if (!valueA) {
      return isAscending ? 1 : -1
    } else if (!valueB) {
      return isAscending ? -1 : 1
    } else {
      return isAscending ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA)
    }
  })

  return sortedEntries
}

export const sortFilterOptions = (options) => {

  const updatedOptions = options.toSorted((a, b) => {

    if ((typeof a.label === 'string') && (typeof b.label === 'string')) {
      const lowerA = a.label.toLowerCase();
      const lowerB = b.label.toLowerCase();
      return lowerA.localeCompare(lowerB, undefined, { numeric: true });
    } else return (a.label < b.label) ? 1 : -1
  })

  return updatedOptions
}

export const cellContent = (header, contentArray) => {
  if (header.type === 'MathHeader') return mathCellContent(header, contentArray)
  if (header.type === 'AutoHeader') return autoHeaderContent(header, contentArray)
  return contentArray.find(attribute => attribute.tracker_header_id === header.id)
}

const mathCellContent = (header, contentArray) => {
  const operations = []
  const values = []
  header.tracker_header_math_options.map(option => {
    values.push(contentArray.find(attribute => attribute.tracker_header_id === option.tracker_header_reference_id))
    operations.push(option.operation)
  })
  const content = values.reduce((acc, current, index) => {
    const value = current?.value || null
    const operation = operations[index - 1]
    if (acc === null || value === null) return null
    if (index === 0)
      return value
    switch (operation) {
      case 'add':
        return acc + Number(value)
      case 'subtract':
        return acc - Number(value)
      case 'multiply':
        return acc * Number(value)
      case 'divide':
        return Number(value) !== 0 ? acc / Number(value) : acc; // Avoid division by zero
      default:
        return acc; // If operation is unknown, return the accumulator unchanged
    }
  }, 0)

  if (content !== null) {
    return Math.round(content * 100) / 100
  } else return content

}

const autoHeaderContent = (header, contentArray) => {
  const filteredArray = contentArray.filter(attribute => attribute.tracker_header_id === header.id)
  return filteredArray.find(attribute => attribute.id) || filteredArray[0]
}

const findMetaOfType = (header, type) => header.tracker_header_metas.find(meta => meta.meta_type === type)

export const reduceToCoords = (autoHeaders, attributes, project) => {
  return autoHeaders.reduce((acc, currentHeader) => {
    const coordName = findMetaOfType(currentHeader, 'Coordinate')?.meta_value
    if (coordName) {
      const coordContent = cellContent(currentHeader, attributes)?.value || project?.site?.[coordName]

      return coordContent && { ...acc, [coordName]: coordContent }
    }
  }, {})
}

export const convertDateValues = (headerType) => (valuesWithHeader) => {
  if (headerType !== 'DateHeader') return valuesWithHeader

  return valuesWithHeader.reduce((acc, current) => {
    const parsedValue = parseDateString(current?.value)

    if (isValid(parsedValue)) {
      acc.push({ label: parsedValue.toDateString(), value: parsedValue.toDateString() })
    } else {
      acc.push({ label: current?.label, value: current?.value })
    }

    return acc
  }, [])
}
