import axios from "axios";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useNoteFilterContext } from "../contexts/noteFilterContext";
import { useNotesContext } from "../contexts/notesContext";

const useNoteQuery = () => {
  const { notes, routes } = useNotesContext()
  const { selectedFormTemplateName, selectedForemanName, selectedActivity, selectedDate } = useNoteFilterContext()

  // This front end cache should get deprecated once the backend does better caching / indexing for project notes
  const [noteCache, setNoteCache] = useState({ default: notes })

  const [notesToView, setNotesToView] = useState(notes)

  useEffect(() => {
    if (noteCache?.default.length !== notes.length) setNotesToView(notes)
    setNoteCache(prevState => ({ ...prevState, default: notes }))
  }, [notes])

  const currentQuery = () => {
    let query = ""
    if (selectedDate) {
      query += format(selectedDate, 'yyyy-MM-dd')
    }

    if (selectedFormTemplateName) {
      query += selectedFormTemplateName.value
    }

    if (selectedActivity) {
      query += selectedActivity.value
    }

    if (selectedForemanName) {
      query += selectedForemanName.value
    }
    return query
  }

  useEffect(() => {
    const query = currentQuery()

    if (!query) {
      setNotesToView(noteCache.default)
    } else if (noteCache[query]) {
      setNotesToView(noteCache[query])
    } else {
      setNotesToView([])
      axios
        .get(routes.notesPath, {
          params: {
            date: selectedDate && format(selectedDate, 'yyyy-MM-dd'),
            form_template_id: selectedFormTemplateName?.value,
            activity: selectedActivity?.value,
            foreman_name: selectedForemanName?.value
          }
        })
        .then((response) => {
          const { data } = response
          setNotesToView(data)

          setNoteCache(prev => ({ ...prev, [query]: data }))
        })
    }
  }, [selectedDate, selectedFormTemplateName, selectedActivity, selectedForemanName])

  return { notesToView }
}

export default useNoteQuery
