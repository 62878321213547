import { isEmpty } from 'lodash'
import React from 'react'
import { useMobileRecordContext } from "../../../contexts/mobileRecordContext"
import { seperateChildRecords } from '../../../utilities/mobileRecordHelper'
import GroupedChildRecords from './groupedChildRecords'

const ChildRecords = () => {
  const { childRecords } = useMobileRecordContext()
  const { sampleDetails, groupedRecords } = seperateChildRecords(childRecords)

  return (
    <div className='flex flex-col mb-8 w-full'>
      {!isEmpty(sampleDetails) &&
        <div key={sampleDetails[0]?.record_type} className='flex flex-col'>
          <GroupedChildRecords recordType={'SAMPLE DETAILS'} records={sampleDetails} />
        </div>}
      {!isEmpty(groupedRecords) && Object.entries(groupedRecords).map(([recordType, records]) => (
        <div key={recordType} className='flex flex-col'>
          <GroupedChildRecords recordType={recordType} records={records} />
        </div>
      ))}
    </div>
  )
}

export default ChildRecords
