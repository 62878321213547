import React, { createContext, useContext, useEffect, useMemo, useReducer } from 'react'
import { updateEntryApi } from '../api/mobile/entriesApi'
import { updateEntryHelper } from '../utilities/mobileRecordHelper'

/* Actions */
const UPDATE_STATE = 'UPDATE_STATE'
const INIT = 'INIT'
const UPDATE_ENTRY = 'UPDATE_ENTRY'

/* Initial State */

const initialState = {
  mobileRecord: {},
  childRecords: [],
}

const initState = (mobileRecord) => ({ ...initialState, mobileRecord: mobileRecord, childRecords: mobileRecord?.child_records })

/* Reducer */
const mobileRecordReducer = (state, action) => {
  switch (action?.type) {
    case UPDATE_STATE:
      return { ...state, ...action.payload }
    case INIT:
      return { ...state, ...initState(action.initState) }
    case UPDATE_ENTRY:
      const updatedRecord = updateEntryHelper(state.mobileRecord, action.payload)
      return { ...state, mobileRecord: updatedRecord }
    default:
      return state
  }
}

/* Contexts */

const MobileRecordContext = createContext(initialState)

const MobileRecordApiContext = createContext({
  updateState: () => {},
  updateEntry: () => {}
})

/* Provider */
export const MobileRecordProvider = ({ children, mobileRecord }) => {
  const [state, dispatch] = useReducer(mobileRecordReducer, initialState)

  useEffect(() => void dispatch({ type: INIT, initState: mobileRecord }), [mobileRecord])

  const api = useMemo(() => {
    const updateState = (field, value) => dispatch({ type: UPDATE_STATE, payload: { [field]: value } })

    const updateEntry = (entry) => {updateEntryApi(entry).then(res => { dispatch({ type: UPDATE_ENTRY, payload: res })})
    }
    return {
      updateState,
      updateEntry
    }
  }, [])



  return (
    <MobileRecordApiContext.Provider value={api}>
      <MobileRecordContext.Provider value={state}>
        {children}
      </MobileRecordContext.Provider>
    </MobileRecordApiContext.Provider>

  )
}

/* Custom Context Hooks */
export const useMobileRecordContext = () => useContext(MobileRecordContext)
export const useMobileRecordApi = () => useContext(MobileRecordApiContext)
