import React from 'react'
import EntryValue from './entryValue'

const Entry = ({ entry }) => {

  return (
    <table className='w-full border-collapse'>
      <tbody>
        <tr>
          <th
            className='w-1/2 py-2 font-semibold text-xs text-gray-600 uppercase text-left pr-2 whitespace-nowrap pr-2'>{entry.label}</th>
          <td className='w-1/2'>
            <EntryValue entry={entry} />
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default Entry
