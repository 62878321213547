import React, { useEffect, useState } from "react"
import Portfolio from "./portfolio"
import { simpleGet } from "../../../api/apiConfig"


const Portfolios = () => {
  const [portfolios, setPortfolios] = useState([])

  useEffect(() => {
    simpleGet('/admin/portfolios.json')
      .then(res => setPortfolios(res))
      .catch(err => console.log(err))
  }, [])

  return <>
    { portfolios.map(portfolio => <Portfolio key={ portfolio.id } portfolio={ portfolio }/>) }
  </>
}

export default Portfolios
