import { daysOfWeek } from "../constants"

export const optionize = (value, label = value) => ({ value, label })

export const reducePortfolios = portfolios => {
  return Object.keys(portfolios).map(portfolio_id => optionize(portfolio_id, portfolios[portfolio_id]))
}

export const reduceProjects = (projects, portfolioId) => {
  return Object.keys(projects).reduce((acc, projectId) => {
    if (portfolioId && parseInt(portfolioId) !== projects[projectId]?.portfolio_id) return acc
    acc[0].push(optionize(projectId, projects[projectId].job_number))
    acc[1].push(optionize(projectId, projects[projectId].name))
    if (projects[projectId].historical_project_number) {
      acc[2].push(optionize(projectId, projects[projectId].historical_project_number))
    }
    return acc
  }, [[], [], []])
}

export const projectOptionsValues = (projects, projectId) => {
  if (projectId) {
    return [
      optionize(projectId, projects[projectId].job_number),
      optionize(projectId, projects[projectId].name),
      optionize(projectId, projects[projectId].historical_project_number),
    ]
  } else {
    return [null, null, null]
  }
}

export const apiVerifier = formRef => {
  const formData = new FormData(formRef.current)
  const [projectId, taskId, date] =
    [formData.get('project_id'), formData.get('timesheet_task_id'), formData.get('date')]
  return (projectId && taskId && date) ? formData : null
}

export const optionizeTasks = (tasks) => tasks.map(task => optionize(task.id, task.name))

const sumHours = timeEntries => timeEntries?.reduce((acc, entry) => acc + parseFloat(entry?.hours), 0.0) || 0

export const weeklyTotals = entries => {
  const sundayTotal = sumHours(entries?.sunday?.time_entries)
  const mondayTotal = sumHours(entries?.monday?.time_entries)
  const tuesdayTotal = sumHours(entries?.tuesday?.time_entries)
  const wednesdayTotal = sumHours(entries?.wednesday?.time_entries)
  const thursdayTotal = sumHours(entries?.thursday?.time_entries)
  const fridayTotal = sumHours(entries?.friday?.time_entries)
  const saturdayTotal = sumHours(entries?.saturday?.time_entries)

  return {
    sundayTotal: sundayTotal,
    mondayTotal: mondayTotal,
    tuesdayTotal: tuesdayTotal,
    wednesdayTotal: wednesdayTotal,
    thursdayTotal: thursdayTotal,
    fridayTotal: fridayTotal,
    saturdayTotal: saturdayTotal,
    total: sundayTotal + mondayTotal + tuesdayTotal + wednesdayTotal + thursdayTotal + fridayTotal + saturdayTotal
  }
}

export const addTimeTables = (timeTables, newTimeTables) => {
  return { ...timeTables, ...newTimeTables }
}

export const getUsername = (users, userId) => {
  const user = users.find(user => user[0] === parseInt(userId))
  return user ? user[1] : "Unknown User"
}

export const updateDayEntry = (week, dayEntry) => {
  const weekCopy = { ...week }
  const dayName = getWeekDayName(week, dayEntry.date)
  weekCopy[dayName] = dayEntry
  return weekCopy
}

export const updateTimeEntryValue = (week, dayEntry, timeEntry) => {
  const weekCopy = { ...week }
  const dayName = getWeekDayName(week, dayEntry.date)
  const day = weekCopy[dayName]

  const entryIndex = day.time_entries.findIndex(entry => entry.id === timeEntry.id)

  if (entryIndex === -1) {
    day.time_entries.push(timeEntry)
  } else {
    day.time_entries[entryIndex] = timeEntry
  }

  weekCopy[dayName] = day
  return weekCopy
}


const getWeekDayName = (week, dateString) => {
  const date = new Date(`${ dateString }T00:00:00`)
  const dayName = date.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase()
  return dayName
}

export const updateTimeEntryWithId = (week, timeEntry) => {
  const weekCopy = { ...week }
  for (let i = 0; i < daysOfWeek.length; i++) {
    const day = daysOfWeek[i]
    const dayEntry = weekCopy[day]

    if (dayEntry.id === timeEntry.timesheet_day_entry_id) {
      dayEntry.time_entries = dayEntry.time_entries.map(entry => {
        return entry.id === timeEntry.id ? timeEntry : entry
      })
      weekCopy[day] = dayEntry
      break
    }
  }
  return weekCopy
}

export const removeTimeEntryWithId = (week, dayEntryId, timeEntryId) => {
  const weekCopy = { ...week }
  for (let i = 0; i < daysOfWeek.length; i++) {
    const day = daysOfWeek[i]
    const dayEntry = weekCopy[day]
    if (dayEntry.id === dayEntryId) {
      const updatedTimeEntries = dayEntry.time_entries.filter(entry => entry.id !== timeEntryId)
      const updatedDayEntry = { ...dayEntry, time_entries: updatedTimeEntries }
      weekCopy[day] = updatedDayEntry
      break
    }
  }
  return weekCopy
}
