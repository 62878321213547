import React, { useMemo } from "react"
import { useParams } from "react-router-dom"
import { set } from "lodash"
import { openInNewTab } from "../../../utilities/helpers"
import { deleteReport } from "../../../api/reportsApi"
import { tableStyles } from "../../../utilities/stylesHelpers"
import StandardButton from "../../shared/standardButton"
import { notifyAlert, notifyError } from "../../shared/notice"

const ReportsExportRow = ({ reportName, reportDate, reportId, setTrackerReports, trackerReport, toggleModal }) => {
  const { portfolioId, projectId } = useParams()
  const params = useMemo(() => {
    const params = {}
    if (reportId) set(params, 'id', reportId)
    return params
  }, [reportId])

  const onPreview = () => openInNewTab(`${ trackerReport?.report_export_file_path }.json`)

  const onDelete = async () => {
    try {
      await deleteReport({ portfolioId, projectId, params })
      setTrackerReports(state => state.filter(tr => tr.id !== params.id))
      notifyAlert('Tracker report successfully deleted')
    } catch (e) {
      notifyError('Tracker report could not be deleted')
    }
  }

  return (
    <tr>
      <td className={ tableStyles.td }>{ reportName }</td>
      <td className={ tableStyles.td }>{ reportDate }</td>
      <td className={ tableStyles.td }>
        <StandardButton onClickHandler={ () => toggleModal({ report: trackerReport, modalType: 'email' }) }
                        buttonText="Email"/>
        <StandardButton onClickHandler={ onPreview } buttonText="Preview"/>
        <StandardButton onClickHandler={ () => toggleModal({ report: trackerReport, modalType: 'history' }) }
                        buttonText="View History" btnClass='btn-orange'/>
        <StandardButton onClickHandler={ onDelete } buttonText="Delete" btnClass="btn-outline"/>
      </td>
    </tr>
  )
}

export default ReportsExportRow
