import { exportAllBoreholes } from "../../api/boreholeExportApi";
import { isEmpty } from 'lodash'
import { notifyAlert, notifyError } from '../shared/notice'

const getSelectedBorehole = (id, boreholes) => {
  return boreholes.find(borehole => borehole.id == id)
}

const downloadData = (data, filename) => {
  const blob = new Blob([data], { type: 'application/zip' })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = filename
  link.click()
}

const missingDataErrors = (selectedBoreholes) => {
  const boreholes = selectedBoreholes.filter(borehole => !borehole.has_sample_details_data)

  if (!isEmpty(boreholes)) {
    const notExportedBoreholesString = boreholes.map(borehole => borehole.title).join(", ")

    return notifyError(`The following boreholes were not exported due to missing sample data: ${ notExportedBoreholesString }`)
  }
}

const depthErrors = (selectedBoreholes) => {
  const boreholes = selectedBoreholes.filter(borehole => borehole.depth_error)

  if (!isEmpty(boreholes)) {
    if (boreholes.length === 1) {
      return notifyError(`Export failed: ${ boreholes[0].depth_error }`)
    } else {
      const notExportedBoreholesString = boreholes.map(borehole => borehole.title).join(", ")

      return notifyError(`The following boreholes were not exported due to incorrect total depth values: ${ notExportedBoreholesString }`)
    }
  }
}

const locationError = (selectedBoreholes) => {
  const boreholes = selectedBoreholes.filter(borehole => !borehole.has_feature)
  if (!isEmpty(boreholes)) {
    const notExportedBoreholesString = boreholes.map(borehole => borehole.title).join(", ")

    return [notifyError(`The following boreholes are missing geo-location feature ${ notExportedBoreholesString }`), false]
  }
}

const validBoreholes = (boreholeDetails) => {
  const validBoreholes = boreholeDetails.selected_boreholes.filter(borehole => borehole.has_sample_details_data && !borehole.depth_error && borehole.has_feature)
  if (validBoreholes.some(borehole => borehole.is_utm) && !boreholeDetails.utmZone) {
    notifyError('Please supply a valid utm zone.')
    return validBoreholes.filter(borehole => !borehole.is_utm)
  } else {
    return validBoreholes
  }
}

const successfulBoreholes = (boreholeDetails) => {
  const availableBoreholes = validBoreholes(boreholeDetails)

  if (isEmpty(availableBoreholes)) {
    return undefined
  }

  const exportedBoreholesString = availableBoreholes.map(borehole => borehole.title).join(", ")

  notifyAlert(`Please wait, the following boreholes are being exported: ${ exportedBoreholesString }`)

  return availableBoreholes
}

const utmErrors = (boreholeDetails) => {
  const selectedBoreholes = boreholeDetails.selected_boreholes
  const utmBoreholes = selectedBoreholes.filter(borehole => borehole.is_utm)

  if (!isEmpty(utmBoreholes) && !boreholeDetails.utmZone) {
    const notExportedBoreholesString = utmBoreholes.map(borehole => borehole.title).join(", ")

    return notifyError(`The following boreholes were not exported due to having utm coordinates without a zone: ${ notExportedBoreholesString }.`)
  }
}

export const exportSelectedBoreholes = (boreholeDetails, project) => {
  missingDataErrors(boreholeDetails.selected_boreholes)
  depthErrors(boreholeDetails.selected_boreholes)
  locationError(boreholeDetails.selected_boreholes)
  utmErrors(boreholeDetails)

  const boreholeParams = {
    borehole_ids: successfulBoreholes(boreholeDetails)?.map(borehole => borehole.id),
    project: boreholeDetails.project,
    client: boreholeDetails.client,
    legal: boreholeDetails.legal,
    checked_by: boreholeDetails.checked_by,
    useLatLon: boreholeDetails.useLatLon,
    utmZone: boreholeDetails.utmZone,
  }

  if (boreholeParams.borehole_ids) {
    return exportAllBoreholes(project.id, boreholeParams).then(res => downloadData(res.data, `${ project.name }_borehole_logs.zip`))
  }
}
