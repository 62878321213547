import { parseDateString } from "./dateHelpers"

export const whichProject = (content, projectArray) => projectArray.find(project => project.id === content.project_id)
export const whichAttribute = (headerId, attributes) => attributes.find(attr => attr?.tracker_header_id === headerId)
export const subjectValue = (attributeValue) => {
  const parsedContent = parseDateString(attributeValue)
  if (parsedContent) {
    return parsedContent.toDateString()
  } else {
    return attributeValue
  }
}
