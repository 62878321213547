import React, { useCallback, useMemo } from 'react'
import { isEmpty } from "lodash"
import { useAiDocumentAPI, useAiDocumentContext } from "../../../contexts/aiDocumentContext"
import { fileNameParsed } from "../../../utilities/aiDocumentHelpers"
import AiDocumentBreadcrumbs from "../shared/aiDocumentBreadcrumbs"
import { formatDateTimeStr } from "../../../utilities/dateHelpers"
import AnimatedCheckbox from "../../shared/animatedCheckbox"
import LoadingSpinner from "../../shared/loadingSpinner"
import { byteToMB } from "../../../utilities/helpers"
import BaseStatusModal from "../shared/statusModal"
import EditModal from "../shared/editModal"
import { useNavigate } from "react-router-dom"
import { notifyError } from "../../shared/notice"
import { deleteDocuments } from "../../../api/aiDocumentsApi"
import ActionButtons from "../shared/actionButtons"
import TextractTables from "./textractTables"
import QueryResults from "./queryResults"
import QueryModal from "../shared/queryModal"

const AiDocument = () => {
  const navigate = useNavigate()
  const { document, modals, statuses } = useAiDocumentContext()
  const {
    toggleModal, updateJson, generateTables, uploadToOpenAi, onGetBasicSummary, updateStatus
  } = useAiDocumentAPI()
  const filename = useMemo(() => fileNameParsed(document.file_name), [document])
  const { id: documentId, status } = useMemo(() => document, [document])

  const onDelete = useCallback(() => {
    deleteDocuments([documentId])
      .then(() => navigate('/ai/documents'))
      .catch(() => notifyError('Failed to delete document'))
  }, [documentId])

  return (
    <div className="py-4 px-8">
      <AiDocumentBreadcrumbs documentName={ filename }/>
      <h1 className="text-3xl font-bold">{ filename }</h1>
      { isEmpty(document) && <LoadingSpinner size="2xl"/> }
      { !isEmpty(document) && (<>
        <div className="w-full grid grid-cols-4 py-4">
          <p><strong>Status:</strong> { status[0].toUpperCase() + status.slice(1) }</p>
          <p><strong>Size:</strong> { byteToMB(document.file_size) } MB</p>
          <p><strong>Updated At:</strong> { formatDateTimeStr(document?.updated_at, 'yyyy-MM-dd hh:mmaaa') }</p>
          <div className="flex flex-row">
            <p><strong>AI Ready:</strong></p>&nbsp;<AnimatedCheckbox checked={ !!document?.openai_id }/>
          </div>
        </div>
        <div className="w-full py-4 grid grid-cols-3 items-start">
          <div className="flex flex-col gap-4">
            <div>
              <p>Summary:</p>
              <p>{ document.summary || "Summary has not been generated yet." }</p>
            </div>
            { document.errors_status && (
              <div>
                <p>Errors:</p>
                { document.errors_status.map((error, index) => (
                  (typeof error === 'string') ? <p key={ [document.id, index, 'error'].join("-") }>{ error }</p> : ''
                )) }
              </div>
            ) }
          </div>
          <ActionButtons document={ document } toggleModal={ toggleModal } generateTables={ generateTables }
                         uploadToOpenAi={ uploadToOpenAi } onGetBasicSummary={ onGetBasicSummary }
                         onDelete={ onDelete }/>
        </div>
      </>) }
      { !isEmpty(document) && <QueryResults/> }
      { !isEmpty(document) && <TextractTables document={ document }/> }
      <EditModal modals={ modals } toggleModal={ toggleModal } updateJson={ updateJson }/>
      <BaseStatusModal statuses={ statuses } modals={ modals } toggleModal={ toggleModal }
                       updateStatus={ updateStatus }/>
      <QueryModal statuses={ statuses } modals={ modals } toggleModal={ toggleModal }
                  updateStatus={ updateStatus }/>
    </div>
  )
}

export default AiDocument
