import React, { useMemo } from "react"
import { parseDateString } from "../../utilities/dateHelpers"

const DevBlogCard = ({ post, indexView = false }) => {
  const TitleTag = useMemo(() => indexView ? 'h3' : 'h1', [indexView])

  const dateToShow = useMemo(() => {
    const parsedContent = parseDateString(post.published_at)
    return parsedContent ? parsedContent.toDateString() : post?.published_at
  }, [post])

  return (
    <div className="w-full p-4 flex flex-col items-center gap-6">
      <TitleTag className="font-bold text-5xl text-center">{ post.title }</TitleTag>
      { indexView && <p>{ post.summary }</p> }
      <p className="text-sm text-slate-600 text-right">{ dateToShow }</p>
    </div>
  )
}

export default DevBlogCard
