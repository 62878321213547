import React, { useEffect, useRef } from "react"
import { useNotesApiContext, useNotesContext } from "../../contexts/notesContext"
import { parseDateString, syncAtFormatter } from "../../utilities/dateHelpers"

const SyncedAt = () => {
  const { loading, syncedAt, project } = useNotesContext()
  const { updateState } = useNotesApiContext()
  const renderRef = useRef(-1)

  useEffect(() => {
    if (!loading) {
      renderRef.current += 1
    }

    if (!loading && renderRef.current === 0) {
      const formattedDate = project.synced_at ? syncAtFormatter(parseDateString(project.synced_at)) : 'N/A'
      updateState('syncedAt', formattedDate)
    }

    if (!loading && renderRef.current > 0) {
      updateState('syncedAt', syncAtFormatter())
    }
  }, [loading])

  return (
    <span>
      {'Last synced at: '}
      {loading && 'Updating...'}
      {!loading && syncedAt}
    </span>
  )
}

export default SyncedAt
