export const getBoreholeId = (mobileRecord) => {
  const boreholeEntry = mobileRecord.entries.find(entry => entry.name === "boreholeId")
  const boreholeId = boreholeEntry ? boreholeEntry.value : undefined
  
  return boreholeId
}

export const updateEntryHelper = (mobileRecord, entry) => {
  const updatedEntries = mobileRecord.entries.map(e => e.id === entry.id ? entry : e)
  return { ...mobileRecord, entries: updatedEntries }
}

export const alphaNumericRecordEntries = (record) => {
  const alphaNumericEntries = record.entries.sort((a, b) => a.name.localeCompare(b.name))
  record = { ...record, entries: alphaNumericEntries }
  return record
}

export const seperateChildRecords = (childRecords) => {
  const { sampleDetails, groupedRecords } = childRecords.reduce(
    (acc, record) => {
      if (record.record_type === "SampleDetail") {
        acc.sampleDetails.push(record) // Add to sampleDetails
      } else {
        (acc.groupedRecords[record.record_type] ??= []).push(record) // Group other records by type
      }
      return acc;
    },
    { sampleDetails: [], groupedRecords: {} } // Initialize with separate objects
  )

  return { sampleDetails, groupedRecords }
}
