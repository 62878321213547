import { findKey } from "lodash";
import React from "react";
import DatePicker from "react-datepicker";
import { useNoteFilterApi, useNoteFilterContext } from "../../../contexts/noteFilterContext";
import { useNotesContext } from "../../../contexts/notesContext";
import NoteFilter from "./NoteFilter";

const NoteFilters = () => {
  const { loading, routes } = useNotesContext()
  const { activityList, formTemplateNameList, foremanNameList, dateList, selectedDate } = useNoteFilterContext()
  const { setFilter } = useNoteFilterApi()

  const hasReport = () => !!findKey(routes, (_, key) => key.includes('Report'))

  return (
    <div className='w-full flex flex-col lg:flex-row items-center pr-2'>
      <NoteFilter
        label={'Form Name'}
        onChange={setFilter('selectedFormTemplateName')}
        options={formTemplateNameList}
        isDisabled={loading}
      />
      {hasReport() && <>
        <NoteFilter
          label={'Activity'}
          onChange={setFilter('selectedActivity')}
          options={activityList}
          isDisabled={loading}
        />
        <NoteFilter
          label={'Foreman Name'}
          onChange={setFilter('selectedForemanName')}
          options={foremanNameList}
          isDisabled={loading}
        />
      </>}
      <div className={'flex flex-col'}>
        <p className='px-1 capitalize'>Date</p>
        <div className='flex items-center'>
          <DatePicker
            className='block p-2 text-gray-700 appearance-none focus:outline-none border border-gray-200 focus:shadow-inner m-0 rounded'
            selected={selectedDate}
            includeDates={dateList}
            onChange={setFilter('selectedDate')}
            disabled={loading}
          />
          <button
            className='focus-outline:none btn btn-primary mx-1'
            onClick={() => setFilter('selectedDate')(null)}
          >Reset
          </button>
        </div>
      </div>
    </div>

  )
}

export default NoteFilters
