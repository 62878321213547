import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { format } from "date-fns";
import React, { useEffect, useMemo, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { usePhotoAPIContext } from '../../contexts/photoContext';
import { parseDateString } from "../../utilities/dateHelpers";
import Modal from "../shared/modal";
import { notifyAlert, notifyError } from "../shared/notice";
import Revisions from "../shared/revisions";
import FormEntrySelect from "./formEntrySelect";
import PinMovementRequests from "./pinMovementRequests";

const FormEntry = ({ formField, originalFormEntry, routes, className = '' }) => {
  let [formEntry, setFormEntry] = useState(originalFormEntry)
  let [value, setValue] = useState()
  let [modalIsOpen, setModalIsOpen] = useState(false)
  const { updateOpenIndex } = usePhotoAPIContext()

  useEffect(() => {
    if (formEntry) {
      setValue(formEntry.value)
    }
  }, [formEntry])

  const parsedValue = useMemo(() => {
    if (value) return parseDateString(value)
    return null
  }, [value])

  const submitField = (value) => {
    axios.put(routes.updateFormEntryPath, {
      form_entry: { value: value }
    }).then(res => {
      setFormEntry(res.data)
      notifyAlert('Saved!')
      closeModal()
    }).catch(e => {
      notifyError('Something went wrong...')
    })
    PinMovementRequests(formField, value, formEntry, routes)
  }

  const renderFormField = () => {
    switch (formField.component_type) {
      case 'textInput':
      case 'textArea':
        return <textarea
          className="resize max-w-full w-full"
          defaultValue={value}
          onChange={e => setValue(e.target.value)}
        />
      case 'location':
        return (
          <input
            type='text'
            value={value}
            onChange={e => setValue(e.target.value)}
            className='block w-full p-1 text-xs text-gray-700 appearance-none focus:outline-none border border-gray-200 focus:shadow-inner m-0 rounded'
          />
        )
      case 'numberInput':
        return (
          <input
            type='number'
            step='any'
            className='block w-full p-1 text-xs text-gray-700 appearance-none focus:outline-none border border-gray-200 focus:shadow-inner m-0 rounded'
            defaultValue={value} onChange={e => setValue(e.target.value)}
          />
        )
      case 'select':
        return <FormEntrySelect formField={formField} setValue={setValue} value={value} />
      case 'radio':
        return <FormEntrySelect formField={formField} setValue={setValue} value={value} />
      case 'checkbox':
        return <input type='checkbox' checked={value === 't'}
          onChange={e => setValue(e.target.checked ? 't' : 'f')} />
      case 'date':
        return (
          <>
            <DatePicker
              className='block w-full p-1 text-xs text-gray-700 appearance-none focus:outline-none border border-gray-200 focus:shadow-inner m-0 rounded z-1000'
              selected={parsedValue}
              onChange={(date) => {
                setValue(String(format(date, 'yyyy-MM-dd')))
              }}
            />
          </>
        )
      default:
        return <div>{value}</div>
    }
  }

  const openModal = () => {
    setModalIsOpen(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
  }

  switch (formField.component_type) {
    case 'imageupload':
      return (
        <>
          <div className='grid grid-cols-3 gap-x-1 mr-2'>
            {value && value.split(',').map((img, i) =>
              <img alt={img}
                key={i}
                src={`https://prodelta.terragoedge.com/getFormResources.html?resourceGuid=${img}`}
                className='cursor-pointer'
                onClick={() => updateOpenIndex(img)}
              />)}
          </div>
        </>
      )
    case 'signature':
      return (
        <>
          <div className='grid grid-cols-3 gap-x-1 mr-2'>
            <img alt={value}
              key={value}
              src={`https://prodelta.terragoedge.com/getFormResources.html?resourceGuid=${value}`}
              className='cursor-pointer'
              onClick={() => updateOpenIndex(value)}
            />
          </div>
        </>
      )
    default:
      return <div className={`w-40 z-10 flex flex-row border border-gray-300 ${className}`}>
        {formEntry && <>
          <div
            className='flex flex-row items-center justify-between gap-x-2 cursor-pointer group hover:bg-blue-100 p-2 border-gray-300 w-full h-full'
            onClick={openModal}>
            <div className='h-4 whitespace-nowrap truncate text-xs'>{value}</div>
            <FontAwesomeIcon
              className='text-xs opacity-0 group-hover:opacity-100 text-gray-600'
              icon={faEdit}
            />
          </div>
          {modalIsOpen &&
            <Modal closeModal={closeModal} heading={formField.label} onSave={() => submitField(value)}>
              <div className='w-full flex flex-col lg:flex-row lg:justify-between gap-y-4 lg:gap-y-0 lg:gap-x-4'>
                <div className='w-full max-w-[80%] relative'>{renderFormField()}</div>
                <div className='w-full'><Revisions routes={routes} formEntry={formEntry} /></div>
              </div>
            </Modal>}
        </>}
      </div>
  }
}

export default FormEntry
