import axios from "axios"
import React from 'react'
import { useNotesApiContext, useNotesContext } from "../../../contexts/notesContext"
import AnimatedCheckbox from "../../shared/animatedCheckbox"
import { notifyAlert, notifyError } from "../../shared/notice"

const EnableSyncCheckBox = ({ outerClass = '', children }) => {
  const { project, syncEnabled } = useNotesContext()
  const { updateState } = useNotesApiContext()

  const onClickHandler = async () => {
    try {
      const res = await axios.put(`/projects/${ project.id }`, { sync_enabled: !syncEnabled })

      updateState('syncEnabled',res.data.sync_enabled)
      notifyAlert(res.data.sync_enabled ? 'Sync successfully enabled!' : 'Sync successfully disabled!')
    } catch (err) {
      const formattedErrors = err.response.data.errors.map((e) => e.trim()).join(', ')

      notifyError(formattedErrors)
      console.error(formattedErrors)
    }
  }

  return (
    syncEnabled !== null && (
      <div className={ outerClass }>
        <AnimatedCheckbox
          id="enable-sync-checkbox"
          checked={ syncEnabled }
          onClick={ onClickHandler }
        />
        <label htmlFor="enable-sync-checkbox">
          { children }
        </label>
      </div>
    )
  )
}

export default EnableSyncCheckBox
