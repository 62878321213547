import axios from 'axios'
import React, { createContext, useContext, useEffect, useMemo, useReducer } from 'react'
import { useParams } from 'react-router-dom'
import { getProjectRoutes } from "../api/projectApi"
import { notifyError } from "../components/shared/notice"

/* Action */
const UPDATE_STATE = 'UPDATE_STATE'

/* Initial State */
export const initialState = {
  routes: {},
  permits: [],
  permitsPage: window.location.pathname.includes('permits'),
  loading : true,
}

/* Reducer */

const permitReducer = (state, action) => {
  switch (action?.type) {
    case UPDATE_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

/* Contexts */
const PermitContext = createContext(initialState)

const PermitApiContext = createContext({
  getPermits: () => {},
  setPermits: () => {},
  createPermit: () => {},
})

/* Provider */
export const PermitProvider = ({ children, routes }) => {
  const { projectId } = useParams()
  const [state, dispatch] = useReducer(permitReducer, initialState)

  const api = useMemo(() => {
    const updateState = (field, value) => dispatch({ type: UPDATE_STATE, payload: { [field]: value } })

    const getPermits = (permitsPath) => axios.get(permitsPath + '.json')

    return { getPermits, updateState }
  }, [])

  api.createPermit = (permitsPath, params) => {
    api.updateState('loading',true)

    return axios.post(permitsPath + '.json', params)
      .then(res => {
        api.updateState('loading',false)
        api.updateState('permits', [...state.permits, ...res.data])
        return res
      })
      .catch(err => {
        api.updateState('loading',false)
        console.error(err)
        notifyError('Unable to create Permit')
      })
  }

  const internalApi = useMemo(() => {

    const loadPermits = (isPermitsPage, routes) => {
      if (isPermitsPage) {
        api.getPermits(routes.permitsPath)
          .then(response => api.updateState('permits', response.data))
          .then(_ => api.updateState('loading',false))
      }
    }

    const updateProjectRoutes = () => {
      getProjectRoutes(projectId)
        .then((response) => dispatch({ type: UPDATE_STATE, payload: { 'routes': response.data } }))
    }

    return { loadPermits, updateProjectRoutes }
  }, [])

  useEffect(() => {
    if (!state.awaitingJobs) {
      void internalApi.loadPermits(state.permitsPage, routes)
      void internalApi.updateProjectRoutes()
    }
  }, [state.awaitingJobs])

  return (
    <PermitApiContext.Provider value={api}>
      <PermitContext.Provider value={state}>
        {children}
      </PermitContext.Provider>
    </PermitApiContext.Provider>
  )
}

/* Custom Context Hooks */
export const usePermitContext = () => useContext(PermitContext)
export const usePermitApiContext = () => useContext(PermitApiContext)
