import React from 'react'
import NoteFilters from "../projects/shared/NoteFilters"
import Widget from "../shared/widget"
import { classNames } from "../../utilities/utils"

const AssessmentLayout = ({ children, project, showMap, showFormNameFilter }) => {

  return (
    <div className="flex flex-row flex-grow overflow-hidden">
      <div className="flex flex-col h-1/3 lg:h-auto mr-4 w-1/4">
        <Widget widgetProp={project?.default_widget?.full_url} project={project} widgetStyles="h-full sm:p-0" />
      </div>
      <div className={classNames(`w-3/4 h-2/3 overflow-scroll gap-y-1`, showMap && 'lg:h-full')}>
        {showFormNameFilter() && <NoteFilters />}
        {children}
      </div>
    </div>
  )
}

export default AssessmentLayout
