import React, { useEffect, useState } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { notifyAlert, notifyError } from "../shared/notice";
import Export from "./export";
import ExportButton from "./exportButton"
import { parseDateString } from "../../utilities/dateHelpers"

const Exports = ({ dates, exportPath, title, admin }) => {
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null)
  const [exports, setExports] = useState(null)
  const getExports = (exportPath) => {
    axios.get(exportPath).then(res => {
      setExports(res.data)
    })
  }

  useEffect(() => {
    getExports(exportPath)
  }, [])

  const getPdfExport = () => {
    axios.post(exportPath, { date: selectedDate }).then(res => {
      window.open(res.data.pdf_path, '_blank');
      setLoading(false)
      getExports(exportPath)
    })
  }

  const url = exportPath.replace('.json', "")

  const deleteAllReports = () => {
    const result = confirm("Are you sure you want to delete all exports?");
    if (result) {
      axios({
        method: 'post',
        url: `${ url }/destroy_all.json`,
      })
        .then(res => {
          notifyAlert(res.data)
          getExports(exportPath)
        })
        .catch(err => {
          notifyError('There was an error')
          console.error(err)
        })
    }
  }

  const exportHistory = exports?.map((e, key) => {
    return (
      <Export e={ e } key={ key } exportPath={ exportPath } admin={ admin } getExports={ getExports } url={ url }/>)
  })

  return <div className='flex flex-row w-full justify-between p-8'>
    <div className='flex flex-col gap-y-4'>
      <h1>Run { title } Report</h1>
      <DatePicker
        className='block w-full p-1 text-xs text-gray-700 appearance-none focus:outline-none border border-gray-200 focus:shadow-inner m-0 rounded z-1000'
        selected={ selectedDate }
        includeDates={ dates.map(date => parseDateString(date)) }
        onChange={ (date) => {
          setSelectedDate(date)
        } }
      />
      <ExportButton loading={ loading } setLoading={ setLoading } selectedDate={ selectedDate }
                    getPdfExport={ getPdfExport } title={ title }/>
    </div>
    <div className="flex flex-col">
      <div className="flex flex-row justify-between items-center text-xl items-baseline my-2 px-1 gap-x-2">
        <h2 className="text-2xl">Export History</h2>
        { admin && exports?.length > 0 && (
          <p
            className='cursor-pointer'
            onClick={ () => deleteAllReports() }
          >
            Delete All
          </p>
        ) }
      </div>
      <div className="flex flex-col gap-y-2 text-white">
        { exportHistory }
      </div>
    </div>
  </div>

}

export default Exports
