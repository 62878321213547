import axios from 'axios'
import React, { createContext, useContext, useEffect, useMemo, useReducer } from 'react'
import { useParams } from 'react-router-dom'
import { getProject, getProjectRoutes } from '../api/projectApi'
import useInterval from '../hooks/use_interval'

/* Actions */
const UPDATE_STATE = 'UPDATE_STATE'
const INIT = 'INIT'
const SET_AWAITING_JOBS = 'SET_AWAITING_JOBS'
/* Initial State */
const initialState = {
  notes: [],
  project: {},
  selectedNoteId: null,
  routes: {},
  loading: true,
  syncedAt: '',
  syncEnabled: null,
  awaitingJobs: true,
  activeJobRoute: '',
}
/* Reducer */
const notesReducer = (state, action) => {
  switch (action?.type) {
    case INIT:
      return {
        ...state,
        routes: action.payload.routes,
        selectedNoteId: action.payload.selectedNoteId,
        project: action.payload.project,
        syncEnabled: action.payload.syncEnabled,
        activeJobRoute: `/synchronized_jobs/find_active?job_name=ImportNotesJob&project_id=${action.payload.project.id}`,
      }
    case SET_AWAITING_JOBS:
      const activeJobRoute = `/synchronized_jobs/find_active?job_name=ImportNotesJob&project_id=${state.project.id}`
      return { ...state, awaitingJobs: action.payload, activeJobRoute: activeJobRoute }

    case UPDATE_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

/* Contexts */
const NotesContext = createContext(initialState)

const NotesApiContext = createContext({
  syncNotes: () => {},
  setAwaitingJobs: () => {},
})

/* Provider */
export const NotesProvider = ({ children, project, routes }) => {

  const { projectId, noteId } = useParams()
  const [state, dispatch] = useReducer(notesReducer, initialState)

  const api = useMemo(() => {

    const updateState = (field, value) => dispatch({ type: UPDATE_STATE, payload: { [field]: value } })

    const getNotes = (notesPath, projectId) => axios.get(notesPath, { params: { projectId: projectId } })

    const syncNotes = (syncNotesPath) => axios.post(syncNotesPath).then(() => setAwaitingJobs(true))

    const setAwaitingJobs = (boolean) => dispatch({ type: SET_AWAITING_JOBS, payload: boolean})

    return {
      getNotes, syncNotes, setAwaitingJobs, updateState
    }
  }, [])

  const internalApi = useMemo(() => {
    const getActiveJob = (getActiveJobPath) => axios.get(getActiveJobPath)

    const fetchData = async () => {
      const activeJobRes = await getActiveJob(state.activeJobRoute)
      if (activeJobRes.data !== null) {
        api.setAwaitingJobs(true)
      } else {
        api.setAwaitingJobs(false)
      }
    }

    const loadNotes = (routes) => {
      api.getNotes(routes.notesPath)
        .then((response) => api.updateState('notes', response.data))
        .then(() => api.updateState('loading', false))
    }

    const updateProjectRoutes = () => {
      getProjectRoutes(projectId)
      .then((response) => dispatch({ type: UPDATE_STATE, payload: { 'routes': response.data } }))
    }

    const updateSyncedAt = () => {
      void getProject(projectId).then(({ data: { synced_at: syncedAt } }) => {
        syncedAt && api.updateState('syncedAt', syncedAt)
      })
    }

    return { getActiveJob, fetchData, loadNotes, updateProjectRoutes, updateSyncedAt }
  }, [])

  useInterval(async () => {
    if (state.awaitingJobs) {
      const response = await internalApi.getActiveJob(state.activeJobRoute)
      api.setAwaitingJobs(response.data !== null)
    }
  }, 3000)

  useEffect(() => {
    void internalApi.fetchData()
    dispatch({
      type: INIT,
      payload: {
        routes: routes,
        selectedNoteId: noteId,
        project: project,
        syncEnabled: project?.sync_enabled,
      }
    })
  }, [])

  useEffect(() => {
    if (!state.awaitingJobs) {
      void internalApi.loadNotes(routes)
      void internalApi.updateProjectRoutes()
      void internalApi.updateSyncedAt()
    }
  }, [state.awaitingJobs])

  return (
    <NotesApiContext.Provider value={api}>
      <NotesContext.Provider value={state}>
        {children}
      </NotesContext.Provider>
    </NotesApiContext.Provider>
  )
}

/* Custom Context Hooks */
export const useNotesContext = () => useContext(NotesContext)
export const useNotesApiContext = () => useContext(NotesApiContext)

