import { isEmpty } from 'lodash'
import React from 'react'
import { daysOfWeek } from '../../../constants'
import { useTimesheetContext } from '../../../contexts/timesheetContext'
import { tableStyles } from '../../../utilities/stylesHelpers'
import { projectManagerOrGreater } from '../../../utilities/userHelpers'
import { classNames } from '../../../utilities/utils'
import ApprovalButton from './approvalButton'
import ApprovalDay from './approvalDay'

const ApprovalHeader = ({headerLength, week}) => {
  const smallHeaderStyles = classNames(tableStyles.th, tableStyles.thSm)
  const { user } = useTimesheetContext()
  return (<>
    <tr className={`${tableStyles.tableHeaderRow} top-[65px]`}>
      <th className={smallHeaderStyles} colSpan={headerLength}>
        <div className={tableStyles.thContent}></div>
      </th>
      {daysOfWeek.map((day, index) => (
          <ApprovalDay key={index} day={day} dayEntry={week[day]} />
        ))}
      <th className={smallHeaderStyles} />
    </tr>
    <tr className={`${tableStyles.tableHeaderRow} top-[65px]`}>
      <th className={smallHeaderStyles} colSpan={headerLength}>
        <div className={tableStyles.thContent}>Approval Status</div>
      </th>
      {daysOfWeek.map((day, index) => (
        <th className={smallHeaderStyles} key={index}>
          <div key={day} className={tableStyles.thContent}>
          {!isEmpty(week[day]) && projectManagerOrGreater(user) && <ApprovalButton dayEntry={week[day]} />}
          </div>
        </th>
      ))}
      <th className={smallHeaderStyles} />
    </tr>
  </>
  )
}

export default ApprovalHeader
